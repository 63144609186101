import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";
import {
	Typography,
	Button,
	CircularProgress,
	Box,
	Dialog,
	DialogContent,
	IconButton,
	Grid,
	Card,
} from "@material-ui/core";
import ShowGeo from "../../Maps/ShowGeo/ShowGeo";
import { addCityToPlace, getPlace } from "../../../actions/place.js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import StarRating from "../../Maps/ShowGeo/PlaceBox/StarRating.jsx";
import SuggestPlace from "./SuggestPlace/SuggestPlace.jsx";
import useStyles from "./styles.js";
import PostInPlace from "./PostInPlace/PostInPlace.jsx";
import PlaceReviewList from "./PlaceReviewList/PlaceReviewList.jsx";
import {
	translatePlaceType,
	switchIcons,
	translatePlaceTypePrepositionalCase,
	translatePlaceTypePlural,
} from "../../../utils/placeUtils";
import PlaceCityToDo from "./PlaceCityToDo/PlaceCityToDo.jsx";
import SearchGeo from "../../Maps/SelectGeo/SearchGeo/SearchGeo.jsx";
import PlaceInformation from "./PlaceInformation/PlaceInformation.jsx";
import SuggestPlaceInfo from "./SuggestPlaceInfo/SuggestPlaceInfo.jsx";
import PlaceCard from "./PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard.jsx";
import { findNearestPlaces } from "../../../actions/places.js";
import PlacePhotos from "./PlacePhotos/PlacePhotos.jsx";
import HowToGet from "./HowToGet/HowToGet.jsx";
import Amenities from "./Amenities/Amenities.jsx";
import { getPhotoByUrl } from "../../../actions/photo.js";
import AuthorInfo from "../../AuthorInfo/AuthorInfo.jsx";
import { fetchPlaceSelections } from "../../../actions/placeSelections.js";

const LocationDetails = () => {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const place = useSelector((state) => state.place.place);
	const amenities = place?.amenities;
	const nearestPlaces = useSelector((state) => state.place?.nearestPlaces);
	const isLoaadingNearestPlaces = useSelector(
		(state) => state.place?.isLoaadingNearestPlaces
	);
	const titlePhotoObject = useSelector(
		(state) => state?.photo?.selectedPhotos["titlePhoto"]
	);
	const selections = useSelector(
		(state) => state?.placeSelections?.placeSelections
	);

	const [isSuggestedText, setIsSuggestedText] = useState(false);
	const [isAddCity, setIsAddCity] = useState(false);
	const [selectedCity, setSelectedCity] = useState(null);
	const [isInfoFormOpen, setIsInfoFormOpen] = useState(false);
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [visibleImages, setVisibleImages] = useState(10);
	const [hasFetched, setHasFetched] = useState(false);
	const token = document.cookie
		.split("; ")
		.find((row) => row.startsWith("token="));
	let user = token ? JSON.parse(localStorage.getItem("profile")) : undefined;
	user = user?.result;
	let userId = user?._id;
	const dispatchResult = useSelector((state) => state.place.boolean);
	const isLoading = useSelector((state) => state.place.isLoading);
	const [isAddImage, setIsAddImage] = useState(false);
	// Add these refs at the top of the component
	const mapSectionRef = React.createRef();
	const infoSectionRef = React.createRef();
	const photosSectionRef = React.createRef();
	const cityTodoSectionRef = React.createRef();
	const howToGetSectionRef = React.createRef();
	const reviewsSectionRef = React.createRef();
	const postsSectionRef = React.createRef();
	const postsContainerRef = useRef(null);

	console.log(place);

	const scrollToSection = (section) => {
		let offset = -47; // Offset by -47px

		let sectionRef;
		switch (section) {
			case "mapSection":
				sectionRef = mapSectionRef;
				break;
			case "infoSection":
				sectionRef = infoSectionRef;
				break;
			case "photosSection":
				sectionRef = photosSectionRef;
				break;
			case "cityTodoSection":
				sectionRef = cityTodoSectionRef;
				break;
			case "howToGetSection":
				sectionRef = howToGetSectionRef;
				break;
			case "reviewsSection":
				sectionRef = reviewsSectionRef;
				break;
			case "postsSection":
				sectionRef = postsSectionRef;
				break;
			default:
				return;
		}

		const elementPosition =
			sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
		const offsetPosition = elementPosition + offset;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	};

	const switchAddImage = () => {
		setIsAddImage((prevIs) => !prevIs);
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleShowMoreImages = () => {
		setVisibleImages((prev) => prev + 10);
	};

	const switchSuggestText = () => {
		setIsSuggestedText((prevIs) => !prevIs);
	};

	const handleSelectedCity = async (selectedPlace) => {
		setSelectedCity(() => selectedPlace);
		await dispatch(addCityToPlace(place._id, selectedPlace._id));
		setIsAddCity((prev) => !prev);
	};

	const handleOpenMap = () => {
		history.push("/map");
	};

	const areAllAmenitiesFalse = (amenities) => {
		return Object.keys(amenities).every((category) => {
			return Object.keys(amenities[category]).every(
				(subAmenity) => !amenities[category][subAmenity]
			);
		});
	};

	useEffect(() => {
		dispatch(getPlace(id));
	}, []);

	useEffect(() => {
		place?.images &&
			place?.images.length > 0 &&
			dispatch(getPhotoByUrl(place?.images[0].image, "titlePhoto"));
	}, [place]);

	useEffect(() => {
		const handleScroll = () => {
			const postsContainer = postsContainerRef.current;
			if (postsContainer && !hasFetched) {
				const rect = postsContainer.getBoundingClientRect();
				if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
					// Dispatch action
					dispatch(
						findNearestPlaces(
							"1",
							place?.placeType,
							"byDistance",
							place?.geoposition[0],
							place?.geoposition[1]
						)
					);
					setHasFetched(true); // Mark as fetched
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [hasFetched, dispatch, place?.geoposition]);

	useEffect(() => {
		const fetchSelections = async () => {
			if (place?.placeType === "city") {
				await dispatch(fetchPlaceSelections(1, 10, place?._id));
			}
		};

		fetchSelections();
	}, [place, dispatch]);

	const averageRate = place
		? place.placeRate.reduce((total, rate) => total + rate.rate, 0) /
				place.placeRate.length || "N/A"
		: null;

	const averageHotelRate = place ? place?.averageRate : null;
	const averageCheck = place ? place?.averageCheck : null;
	const averageTicketAdult = place ? place?.averageTicketAdult : null;
	const averageTicketChild = place ? place?.averageTicketChild : null;

	const openCity = () => (window.location.href = `/place/${place?.city}`);

	console.log(place);

	const renderContent = () => (
		<div className={classes.menuTitleBox}>
			<div className={classes.cityTitleBox}>
				<Typography variant="h4">{place?.placeName}</Typography>
			</div>
			<div className={classes.cityTitleBox}>
				<div className={classes.rating}>
					<div className={classes.ratingFlex}>
						<StarRating rating={averageRate} />
						<Typography className={classes.rateReports}>
							{place?.placeRate.length ? averageRate.toFixed(1) : "N/A"}
						</Typography>
					</div>
					<Typography className={classes.numberReports}>
						Отзывов: {place?.placeRate.length}
					</Typography>
				</div>
			</div>
			<div className={classes.typeAuthorFlexBox}>
				<div className={classes.cityTitleBoxFinal}>
					<div className={classes.placeTypeBoxes}>
						<Box className={classes.boxType}>
							<img
								src={switchIcons(place?.placeType)}
								className={classes.boxTypeImg}
							/>
							<Typography variant="subtitle1" className={classes.boxTypeText}>
								{translatePlaceType(place?.placeType)}{" "}
							</Typography>
						</Box>
						{place?.placeType !== "city" && place?.cityPlaceName ? (
							<Box className={classes.boxType}>
								<Button
									component="span"
									name="test"
									onClick={openCity}
									className={classes.buttonCity}
								>
									<img
										src="https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png"
										className={classes.boxTypeImg}
									/>
									<Typography
										variant="subtitle1"
										className={classes.boxTypeText}
									>
										{place?.cityPlaceName}
									</Typography>
								</Button>
							</Box>
						) : null}
					</div>
					{place && place?.placeType !== "city" && !place?.city && user ? (
						<Button
							className={classes.buttonPrimary}
							onClick={() => {
								setIsAddCity((prev) => !prev);
							}}
						>
							{isAddCity ? "Закрыть" : "Указать город"}
						</Button>
					) : null}
				</div>
				{titlePhotoObject && (
					<div className={classes.authorBox}>
						<AuthorInfo photoObject={titlePhotoObject} />
					</div>
				)}
			</div>
		</div>
	);

	return (
		<>
			<Helmet>
				<title>
					{`${place?.placeName}`} -{" "}
					{`${translatePlaceType(place?.placeType)?.toLowerCase()}`} на карте
					{place?.placeType === "city"
						? `, что посетить в городе ${place?.placeName}`
						: ""}
					{place?.placeType === "city" ? `, как добраться до города` : ""}
					{place?.placeType === "restaurant" ? `, меню ресторана` : ""}
					{place?.placeType === "restaurant" ? `, фотографии ресторана` : ""}
					{place?.placeType === "hotel" ? `, номера отеля` : ""}
					{place?.placeType === "hotel" ? `, фотографии отеля` : ""}
					{place?.placeType === "museum" ? `, часы работы музея` : ""}
					{place?.placeType === "museum" ? `, информация о музее` : ""}
					{place?.placeType === "site" ? `, информация о месте` : ""}, отзывы -
					ToTravelRu
				</title>
				<meta
					name="description"
					content={`${place?.placeName}, место на карте, ${translatePlaceType(
						place?.placeType
					).toLowerCase()}, координаты, как добраться до ${translatePlaceType(
						place?.placeType
					).toLowerCase()} ${place?.placeName}${
						place?.placeType === "city"
							? `, что посетить в городе ${place?.placeName}`
							: ""
					}${
						place?.placeType === "city"
							? `, путеводитель по городу ${place?.placeName}`
							: ""
					}${
						place?.placeType === "restaurant"
							? `, меню ресторана ${place?.placeName}`
							: ""
					}${
						place?.placeType === "hotel"
							? `, номера отеля ${place?.placeName}`
							: ""
					}${
						place?.placeType === "museum"
							? `, часы работы музея ${place?.placeName}`
							: ""
					} - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${place?.placeName}, карта, место на карте, ${
						place?.placeType === "city" ? "путеводитель" : ""
					} отчет, отчеты, путешествия, travel, totravel, ToTravelRu, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия`}
				/>
			</Helmet>
			<Dialog
				open={openImageDialog}
				onClose={() => setOpenImageDialog(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent style={{ position: "relative", padding: 0 }}>
					<img
						src={selectedImage}
						alt="Enlarged"
						className={classes.dialogImage}
					/>

					<IconButton
						edge="end"
						color="inherit"
						onClick={() => setOpenImageDialog(false)}
						aria-label="close"
						style={{ position: "absolute", top: "10px", right: "10px" }}
					>
						<CloseIcon style={{ color: "#fff" }} />
					</IconButton>
				</DialogContent>
			</Dialog>

			<div className={classes.root}>
				<div
					className={
						place?.images && place?.images.length > 0
							? classes.mainImageContainer
							: classes.noImageScenario
					}
				>
					{place?.images && place?.images.length > 0 ? (
						<>
							<img
								src={place?.images[0].image}
								alt="Main Photo"
								className={classes.mainImage}
							/>
						</>
					) : null}
					{isLoading ? (
						<CircularProgress
							style={{ color: "white", margin: "0 auto" }}
							size="1rem"
						/>
					) : (
						renderContent()
					)}
				</div>
				{isAddCity ? (
					<div className={classes.postsContainer}>
						<Typography variant="h6">Поиск города</Typography>
						<SearchGeo onSelectedPlace={handleSelectedCity} filter="city" />
					</div>
				) : null}

				<Card className={`${classes.buttonRow} ${classes.sticky}`}>
					<Button
						onClick={() => scrollToSection("mapSection")}
						className={classes.buttonPrimaryMenu}
					>
						Карта
					</Button>
					<Button
						onClick={() => scrollToSection("infoSection")}
						className={classes.buttonPrimaryMenu}
					>
						Информация
					</Button>
					<Button
						onClick={() => scrollToSection("photosSection")}
						className={classes.buttonPrimaryMenu}
					>
						Фотографии
					</Button>
					{place?.placeType === "city" ? (
						<Button
							onClick={() => scrollToSection("cityTodoSection")}
							className={classes.buttonPrimaryMenu}
						>
							Что посетить
						</Button>
					) : null}
					{place?.placeType === "city" ? (
						<Button
							onClick={() => scrollToSection("howToGetSection")}
							className={classes.buttonPrimaryMenu}
						>
							Как добраться
						</Button>
					) : null}
					<Button
						onClick={() => scrollToSection("reviewsSection")}
						className={classes.buttonPrimaryMenu}
					>
						Отзывы
					</Button>
					{place?.postsId[0] !== null && (
						<Button
							onClick={() => scrollToSection("postsSection")}
							className={classes.buttonPrimaryMenu}
						>
							Посты
						</Button>
					)}
				</Card>

				<Grid container spacing={2}>
					{/* Левая часть */}
					<Grid item xs={12} sm={6} ref={mapSectionRef}>
						{" "}
						{/* Apply grid item style */}
						<div className={classes.gridItem}>
							<div className={classes.mapContainer}>
								<div className={classes.mapButtonFlex}>
									<Typography variant="h5" gutterBottom>
										Карта
									</Typography>
									<Button
										className={classes.buttonPrimary}
										onClick={handleOpenMap}
									>
										Открыть карту
									</Button>
								</div>
								<ShowGeo place={place} placeComponent={true} />
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} ref={infoSectionRef}>
						<div className={classes.gridItem}>
							<Typography variant="h5">
								Информация о{" "}
								{translatePlaceTypePrepositionalCase(
									place?.placeType
								)?.toLowerCase()}{" "}
								{place?.placeName}
							</Typography>
							{isLoading ? (
								<CircularProgress
									style={{ color: "white", margin: "0 auto" }}
									size="1rem"
								/>
							) : place?.description ? (
								<div className={classes.descriptionTextBox}>
									<Typography className={classes.descriptionText}>
										{place?.description}
									</Typography>
								</div>
							) : dispatchResult ? (
								<Typography>
									Спасибо! Администраторы скоро рассмотрят Ваше предложение!
								</Typography>
							) : (
								<Button
									className={
										isSuggestedText
											? classes.buttonSecondarySuggestText
											: classes.buttonPrimarySuggestText
									}
									onClick={switchSuggestText}
								>
									{isSuggestedText ? "Закрыть" : "Предложить описание места"}
								</Button>
							)}
							<PlaceInformation
								place={place}
								averageHotelRate={averageHotelRate}
								averageCheck={averageCheck}
								averageTicketAdult={averageTicketAdult}
								averageTicketChild={averageTicketChild}
							/>
							{isSuggestedText ? (
								<SuggestPlace
									place={place}
									userId={userId}
									classes={classes}
									isSuggestedText={isSuggestedText}
									setIsSuggestedText={setIsSuggestedText}
								/>
							) : null}

							{isInfoFormOpen && (
								<SuggestPlaceInfo
									place={place}
									userId={userId}
									classes={classes}
									setIsInfoFormOpen={setIsInfoFormOpen}
								/>
							)}
							{user && (
								<Button
									className={classes.buttonPrimary}
									onClick={() => setIsInfoFormOpen(!isInfoFormOpen)}
									style={{ margin: "20px 0" }}
								>
									{isInfoFormOpen ? "Закрыть" : "Добавить информацию о месте"}
								</Button>
							)}
						</div>
					</Grid>
				</Grid>

				<div className={classes.postsContainer} ref={photosSectionRef}>
					<PlacePhotos
						place={place}
						handleShowMoreImages={handleShowMoreImages}
						handleImageClick={handleImageClick}
						user={user}
						isAddImage={isAddImage}
						setIsAddImage={setIsAddImage}
						visibleImages={visibleImages}
						switchAddImage={switchAddImage}
					/>
				</div>

				{place?.placeType === "city" ? (
					<div className={classes.postsContainer} ref={cityTodoSectionRef}>
						<PlaceCityToDo place={place} />
					</div>
				) : null}

				{place?.placeType === "city" && place?.howToGet ? (
					<div className={classes.postsContainer} ref={howToGetSectionRef}>
						<HowToGet place={place} userId={userId} />
					</div>
				) : place?.placeType === "city" && user ? (
					<div className={classes.postsContainer} ref={howToGetSectionRef}>
						<HowToGet place={place} userId={userId} />
					</div>
				) : null}

				<div className={classes.postsContainer} ref={reviewsSectionRef}>
					<PlaceReviewList place={place} user={user} />
				</div>
				{place?.postsId[0] !== null && (
					<div className={classes.postsContainer} ref={postsSectionRef}>
						{" "}
						<Typography variant="h6" gutterBottom>
							Подробные посты о месте
						</Typography>
						<Grid container spacing={2}>
							{place?.postsId.map((postId, index) => (
								<Grid key={index} item xs={12} sm={6} md={4} lg={4}>
									<PostInPlace postId={postId} className={classes.postItem} />
								</Grid>
							))}
						</Grid>
					</div>
				)}
				{place?.placeSelectionsId &&
					place?.placeSelectionsId.length > 0 &&
					place?.placeSelectionsId[0] !== null && (
						<div className={classes.postsContainer}>
							<Typography variant="h6" gutterBottom>
								Подборки мест
							</Typography>
							<Grid container spacing={2}>
								{selections?.length > 0 &&
									selections.map((selection, index) => (
										<Grid item xs={12} sm={6} md={6} key={index}>
											<PlaceCard
												i={index + 1}
												p={selection}
												cardType={"selection"}
											/>
										</Grid>
									))}
							</Grid>
						</div>
					)}
				{amenities && !areAllAmenitiesFalse(amenities) ? (
					<div className={classes.postsContainer}>
						<Amenities amenities={amenities} />
					</div>
				) : null}
				<div className={classes.postsContainerNearest} ref={postsContainerRef}>
					<Typography variant="h6" gutterBottom>
						Ближайшие {translatePlaceTypePlural(place?.placeType).toLowerCase()}
					</Typography>
					{nearestPlaces && nearestPlaces.length > 1 ? (
						isLoaadingNearestPlaces === true ? (
							<CircularProgress size="5rem" color="secondary" />
						) : (
							<Grid container spacing={2}>
								{nearestPlaces.slice(1, 7).map(
									(
										p,
										index // Exclude the first element and limit to 6 elements
									) => (
										<Grid key={index} item xs={12} sm={12} md={6} lg={6}>
											<PlaceCard p={p} />
										</Grid>
									)
								)}
							</Grid>
						)
					) : (
						<Typography>Места не найдены</Typography>
					)}
				</div>
			</div>
		</>
	);
};

export default LocationDetails;
