import React from "react";
import { Helmet } from "react-helmet";
import { Container, Typography, Box } from "@material-ui/core";

const ContentClaimsInfo = () => {
	return (
		<>
			<Helmet>
				<title>Интеллектуальные права и претензии - ToTravelRu</title>
			</Helmet>
			<Container>
				<Box my={4}>
					<Typography variant="h4" gutterBottom>
						Интеллектуальные права и претензии
					</Typography>
					<Typography variant="h5" gutterBottom>
						Информация, размещаемая на сайте ToTravelRu
					</Typography>
					<Typography paragraph>
						Пользователи сайта ToTravelRu (далее - Сайт) размещают информацию на
						Сайте самостоятельно, без первоначальной проверки со стороны
						администрации сайта ToTravelRu (далее - Администрация).
						Администрация не несет ответственности за возможное нарушение прав
						на результаты интеллектуальной деятельности, что соответствует
						общемировой практике, ст. 1253.1 Гражданского Кодекса РФ и ст. 15.7
						Федерального закона «Об информации, информационных технологиях и о
						защите информации». Однако мы готовы предпринимать меры по
						пресечению соответствующих нарушений по заявлениям правообладателей.
					</Typography>

					<Typography variant="h5" gutterBottom>
						Что делать, если ваши права нарушены
					</Typography>
					<Typography paragraph>
						Чтобы сообщить о нарушении, напишите на электронный адрес{" "}
						<a href="mailto:property@totravelru.ru">property@totravelru.ru</a>{" "}
						или пожалуйтесь напрямую на фотографию, нажав на кнопку в правом
						верхнем углу фотографии. В письме или форме укажите:
					</Typography>
					<Typography paragraph>
						1. Название объекта исключительных прав на русском и английском
						языках (если есть).
						<br />
						2. Данные о правообладателе или лице, чьи права нарушены: Ф.И.О.,
						адрес для ответа, а для юридических лиц — название организации,
						юридический и почтовый адреса, ИНН и ОГРН, сайт правообладателя.
						<br />
						3. Контактное лицо правообладателя (ФИО, должность, телефон, email).
						<br />
						4. Обстоятельства нарушения, включая:
						<br />
						- адрес страницы сайта, содержащей нарушающую информацию (ссылка в
						формате: https://totravelru.ru/...);
						<br />- полное описание нарушения и основания, по которым информация
						должна быть удалена.
					</Typography>

					<Typography variant="h5" gutterBottom>
						Необходимые документы
					</Typography>
					<Typography paragraph>
						К письму приложите (в виде скана или копий):
						<br />
						- Для правообладателя — юридического лица — копию свидетельства о
						государственной регистрации.
						<br />
						- Копию документа, подтверждающего наличие исключительных прав у
						заявителя.
						<br />- Копию доверенности на представителя (если обращение подает
						представитель). Если заявитель — руководитель правообладателя,
						приложите документ, подтверждающий его полномочия.
					</Typography>

					<Typography variant="h5" gutterBottom>
						Ответ на письмо
					</Typography>
					<Typography paragraph>
						При получении письма с корректными данными отправитель получит
						подтверждение получения жалобы. Жалоба будет рассмотрена должным
						образом в течение 7 рабочих дней. Если претензия обоснована, спорная
						информация будет удалена и будет решаться вопрос о блокировке
						пользователя, нарушевшего права.
					</Typography>
				</Box>
			</Container>
		</>
	);
};

export default ContentClaimsInfo;
