import { GET_PHOTO_BY_URL } from "../constants/actionTypes";

const initialState = {
	selectedPhotos: {}, // Store photos by unique ID
	selectedPhoto: [], // Store photo when no ID is provided
};

const photoReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PHOTO_BY_URL:
			if (action.payload.id) {
				return {
					...state,
					selectedPhotos: {
						...state.selectedPhotos,
						[action.payload.id]: action.payload.data,
					},
				};
			} else {
				return {
					...state,
					selectedPhoto: action.payload.data,
				};
			}
		default:
			return state;
	}
};

export default photoReducer;
