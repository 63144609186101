import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		position: "relative",
	},
	textWrap: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontSize: "1rem",
		"@media (max-width: 600px)": {
			fontSize: "0.9rem",
		},
		lineHeight: "1.1",
		"& p": {
			margin: "5px 0",
		},
		"& ul": {
			margin: "5px 0",
		},
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		paddingLeft: "30px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
}));
