import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	bodyContainer: {
		paddingLeft: "8px",
		paddingRight: "8px",
	},
	photoContainer: {
		paddingLeft: "0px",
		paddingRight: "0px",
	},
	appBar: {
		borderRadius: 15,
		margin: "30px 0",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	heading: {
		color: "rgba(0,183,255, 1)",
	},
	image: {
		marginLeft: "15px",
	},
	[theme.breakpoints.down("sm")]: {
		mainContainer: {
			flexDirection: "column-reverse",
		},
	},
}));
