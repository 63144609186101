import React, { useState } from "react";
import { TextField, Button, Typography, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import SearchGeo from "../../../Maps/SelectGeo/SearchGeo/SearchGeo";
import PlaceCard from "../../Place/PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard";
import { createPlaceSelection } from "../../../../actions/placeSelections";

const PlaceSelectionCreate = ({ onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [selectedPlaces, setSelectedPlaces] = useState([]);
	const [selectedPlacesFull, setSelectedPlacesFull] = useState([]);

	const handlePlaceSelection = (place) => {
		// Store only the ID of the selected place
		setSelectedPlaces((prev) => [...prev, place._id]);
		setSelectedPlacesFull((prev) => [...prev, place]);
	};

	const handleSaveSelection = async () => {
		// Construct the new selection with only the place IDs
		const newSelection = {
			title,
			description,
			places: selectedPlaces, // Array of place IDs
			createdAt: new Date().toISOString(), // Set createdAt to the current date
		};
		await dispatch(createPlaceSelection(newSelection));
		onClose(); // Close the creation view
	};

	const isSaveDisabled = !title || !description || selectedPlaces.length === 0;

	return (
		<div className={classes.createContainer}>
			<Typography variant="h6">Создать подборку</Typography>
			<TextField
				label="Название подборки"
				variant="outlined"
				fullWidth
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				inputProps={{ maxLength: 200 }} // Limit title to 200 characters
				className={classes.inputField}
			/>
			<TextField
				label="Описание подборки"
				variant="outlined"
				fullWidth
				multiline
				minRows={4}
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				inputProps={{ maxLength: 2500 }} // Limit description to 2500 characters
				className={classes.inputField}
			/>
			<Typography variant="subtitle1">Добавить места</Typography>
			<SearchGeo onSelectedPlace={handlePlaceSelection} />
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={3}
				className={classes.gridContainer}
			>
				{selectedPlacesFull.map((place, index) => (
					<Grid item xs={12} sm={6} md={3} key={index}>
						<PlaceCard p={place} />
					</Grid>
				))}
			</Grid>
			<Button
				variant="contained"
				color="primary"
				onClick={handleSaveSelection}
				className={classes.saveButton}
				disabled={isSaveDisabled} // Disable save button based on conditions
			>
				Сохранить подборку
			</Button>
			<Button
				variant="outlined"
				color="secondary"
				onClick={onClose}
				className={classes.cancelButton}
			>
				Отмена
			</Button>
		</div>
	);
};

export default PlaceSelectionCreate;
