import React, { useState } from "react";
import { Container } from "@material-ui/core";
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect,
	useLocation,
} from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import ReportDetails from "./components/ReportDetails/ReportDetails";
import Profile from "./components/Profile/Profile";
import MainPage from "./components/MainPage/MainPage";
import Support from "./components/Support/Support";
import PostContent from "./components/PostContent/PostContent";
import Verify from "./components/Verify/Verify";
import TokenVerify from "./components/TokenVerify/TokenVerify";
import Footer from "./components/Footer/Footer";
import Map from "./components/Maps/Map/Map";
import Agreement from "./components/Agreement/Agreement";
import Privacy from "./components/Privacy/Privacy";
import ChangePasswordAsk from "./components/ChangePasswordAsk/ChangePasswordAsk";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Notifications from "./components/Notifications/Notifications";
import Regions from "./components/Regions/Regions";
import Region from "./components/Regions/Region/Region";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Place from "./components/Places/Place/Place";
import Feed from "./components/Feed/Feed";

import useStyles from "./styles";
import Alert from "./components/Alert/Alert";
import ProfileList from "./components/ProfileList/ProfileList";
import CreateAlbum from "./components/Albums/CreateAlbum/CreateAlbum";
import PlacesList from "./components/Places/Place/PlacesList/PlacesList";
import PlaceSelectionList from "./components/Places/PlaceSelections/PlaceSelectionList/PlaceSelectionList";
import PlaceSelection from "./components/Places/PlaceSelections/PlaceSelection/PlaceSelection";
import AboutPhoto from "./components/AboutPhoto/AboutPhoto";
import ContentClaimsInfo from "./components/ContentClaimsInfo/ContentClaimsInfo";

const App = () => {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

	return (
		<BrowserRouter>
			<RouterContent user={user} setUser={setUser} />
		</BrowserRouter>
	);
};

const RouterContent = ({ user, setUser }) => {
	const location = useLocation(); // Get the current location inside RouterContent
	const classes = useStyles();

	// Condition to check if the current path is not /photo/:folder/:name
	const isNotPhoto = !location.pathname.startsWith("/photo");

	return (
		<Container
			maxWidth="xl"
			className={isNotPhoto ? classes.bodyContainer : classes.photoContainer}
		>
			{isNotPhoto && <Navbar user={user} setUser={setUser} />}
			<Alert />
			<ErrorBoundary>
				<Switch>
					<Route path="/main" exact component={MainPage} />
					<Route path="/" exact component={() => <Redirect to="/main" />} />
					<Route path="/posts" exact component={Home} />
					<Route path="/posts/search" exact component={Home} />
					<Route path="/support" exact component={Support} />
					<Route path="/posts/:id" exact component={ReportDetails} />
					<Route path="/post/:id" exact component={PostContent} />
					<Route
						path="/auth"
						exact
						component={() => (!user ? <Auth /> : <Redirect to="/posts" />)}
					/>
					<Route path="/user/:login" exact component={Profile} />
					<Route path="/user/:login/friends" exact component={ProfileList} />
					<Route
						path="/user/:login/subscribers"
						exact
						component={ProfileList}
					/>
					<Route path="/users" exact component={ProfileList} />
					<Route path="/verify" exact component={Verify} />
					<Route path="/tokenVerify/:token" exact component={TokenVerify} />
					<Route path="/map" exact component={Map} />
					<Route path="/agreement" exact component={Agreement} />
					<Route path="/privacy" exact component={Privacy} />
					<Route path="/content-claims" exact component={ContentClaimsInfo} />
					<Route
						path="/changePasswordAsk"
						exact
						component={ChangePasswordAsk}
					/>
					<Route
						path="/changePassword/:token"
						exact
						component={ChangePassword}
					/>
					<Route path="/notifications" exact component={Notifications} />
					<Route path="/regions" exact component={Regions} />
					<Route path="/region/:region" exact component={Region} />
					<Route path="/adminpanel" exact component={AdminPanel} />
					<Route path="/place/:id" exact component={Place} />
					<Route path="/place/:id/:type" exact component={PlacesList} />
					<Route path="/cities" exact component={PlacesList} />
					<Route path="/feed" exact component={Feed} />
					<Route path="/create-album" exact component={CreateAlbum} />
					<Route
						path="/place-selections"
						exact
						component={PlaceSelectionList}
					/>
					<Route path="/place-selection/:id" exact component={PlaceSelection} />
					<Route path="/photo/:folder/:name" exact component={AboutPhoto} />
				</Switch>
			</ErrorBoundary>
			<Footer />
		</Container>
	);
};

export default App;
