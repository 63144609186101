import React, { useState, useEffect } from "react";
import {
  Avatar,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { changePassword } from "../../actions/auth.js";
import Input from "./Input";
import PasswordChecklist from "react-password-checklist";

import useStyles from "./styles";

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const successVerify = useSelector((state) => state.auth.msg);

  const isSuccessVerify = successVerify?.status === 200;

  const token = window.location.pathname.split("changePassword/")[1];

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const initialState = {
    password: "",
    confirmPassword: "",
    tokenName: window.location.pathname.split("changePassword/")[1],
  };
  const [formData, setFormData] = useState(initialState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordCheck) {
      dispatch(changePassword(token, formData));
    } else {
      alert("Пароль не соответствует критериям или пароли не совпадают");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenIcon />
        </Avatar>
        <Typography variant="h5">Восстановить пароль</Typography>
        {isSuccessVerify ? (
          <Typography>{successVerify?.data}</Typography>
        ) : (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Input
                name="password"
                label="Новый пароль"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                handleShowPassword={handleShowPassword}
              />
              <Input
                name="confirmPassword"
                label="Повторите пароль"
                handleChange={handleChange}
                type="password"
              />
              <PasswordChecklist
                className={classes.checkList}
                rules={["minLength", "number", "capital", "match"]}
                messages={{
                  minLength: "Пароль должен содержать минимум 8 символов",
                  number: "Пароль должен содержать хотя бы 1 цифру",
                  capital: "Пароль должен содержать хотя бы 1 заглавную букву",
                  match: "Пароли должны совпадать",
                }}
                minLength={8}
                value={formData.password}
                valueAgain={formData.confirmPassword}
                onChange={(isValid) => {
                  setPasswordCheck(isValid);
                }}
                iconSize={14}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Обновить пароль
              </Button>
              <Typography>{successVerify?.data}</Typography>
            </Grid>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default ChangePassword;
