// PlacePhotos.jsx
import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import useStyles from "./styles.js";
import PlaceAddPhoto from "../PlaceAddPhoto/PlaceAddPhoto.jsx";
import ImageSection from "./ImageSection.jsx"; // Import the new component

const PlacePhotos = ({
	place,
	handleShowMoreImages,
	handleImageClick,
	user,
	isAddImage,
	setIsAddImage,
	visibleImages,
	switchAddImage,
}) => {
	const classes = useStyles(); // Use styles if needed

	const histQuarterImages =
		place?.images?.filter((imgObj) => imgObj.type === "histQuarter") || [];
	const businessQuarterImages =
		place?.images?.filter((imgObj) => imgObj.type === "businessQuarter") || [];
	const seaImages =
		place?.images?.filter((imgObj) => imgObj.type === "sea") || [];
	const menuImages =
		place?.images?.filter((imgObj) => imgObj.type === "menu") || [];
	const mealsImages =
		place?.images?.filter((imgObj) => imgObj.type === "meals") || [];
	const interiorImages =
		place?.images?.filter((imgObj) => imgObj.type === "interior") || [];
	const roomsImages =
		place?.images?.filter((imgObj) => imgObj.type === "rooms") || [];
	const poolImages =
		place?.images?.filter((imgObj) => imgObj.type === "pool") || [];
	const restaurantImages =
		place?.images?.filter((imgObj) => imgObj.type === "restaurant") || [];
	const buildingImages =
		place?.images?.filter((imgObj) => imgObj.type === "building") || [];
	const otherImages =
		place?.images?.filter(
			(imgObj) => !imgObj.type || imgObj.type === "other"
		) || [];

	return (
		<div>
			<Typography variant="h6" gutterBottom>
				Фотографии места
			</Typography>

			<ImageSection
				title="Исторический квартал"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/coliseum.png"
				images={histQuarterImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="histQuarter"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Деловой квартал"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png"
				images={businessQuarterImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="businessQuarter"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Море"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/sea-waves.png"
				images={seaImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="sea"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Меню"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/menu.png"
				images={menuImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="menu"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Блюда"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/vegan-food.png"
				images={mealsImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="meals"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Интерьер"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/room.png"
				images={interiorImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="interior"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Номера"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/bed.png"
				images={roomsImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="rooms"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Бассейн"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/outdoor-swimming-pool.png"
				images={poolImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="pool"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Ресторан"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/restaurant.png"
				images={restaurantImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="restaurant"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Здание"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/5-star-hotel.png"
				images={buildingImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="building"
				place={place}
				user={user}
			/>

			<ImageSection
				title="Прочие фото"
				icon="https://img.icons8.com/sf-regular/48/FFFFFF/connection-status-off.png"
				images={otherImages}
				visibleImages={visibleImages}
				handleImageClick={handleImageClick}
				sectionId="other"
				place={place}
				user={user}
			/>

			{user && !isAddImage ? (
				<Button className={classes.buttonPrimary} onClick={switchAddImage}>
					Добавить фотографии места
				</Button>
			) : null}
			{isAddImage ? (
				<div className={classes.postsContainer}>
					<PlaceAddPhoto
						user={user}
						place={place}
						setIsAddImage={setIsAddImage}
					/>
				</div>
			) : null}
		</div>
	);
};

export default PlacePhotos;
