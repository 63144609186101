import React, { useState } from "react";
import {
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	FormControlLabel,
	Checkbox,
	Typography,
} from "@material-ui/core";

const HotelForm = ({ handleHotelFormChange, place }) => {
	const [formData, setFormData] = useState({
		checkInTime: "",
		checkOutTime: "",
		numberOfFloors: "",
		numberOfRooms: "",
		yearBuilt: "",
		yearRenovated: "",
		isAllowedPets: "",
		breakfastCost: {
			price: "",
			currency: "",
		},
		breakfastTimeStart: "",
		breakfastTimeEnd: "",
		amenities: {
			general: {
				airConditioner: false,
				smokingAreas: false,
				heating: false,
				elevator: false,
				garden: false,
			},
			rooms: {
				phone: false,
				TV: false,
				smokingRooms: false,
				nonSmokingRooms: false,
				slippers: false,
				fridge: false,
				minibar: false,
				freeMinibar: false,
				freeWater: false,
				wardrobe: false,
				bathrobe: false,
				teapot: false,
				coffeeTea: false,
				coffeeMachine: false,
				safe: false,
				roomService: false,
			},
			bathroom: {
				ownBathroom: false,
				bathroomOnFloor: false,
				ownBathroomAmenities: false,
				dosatorAmenities: false,
				showerInAllRooms: false,
				showerInPartRooms: false,
				bathInAllRooms: false,
				bathInPartRooms: false,
			},
			bedroom: {
				pillowChoice: false,
			},
			services: {
				laundry: false,
				selfLaundry: false,
				baggageRoom: false,
				ATM: false,
				currencyExchange: false,
			},
			food: {
				bar: false,
				breakfastBuffet: false,
				breakfastContinental: false,
			},
			internet: {
				freeWifi: false,
				paidWifi: false,
			},
			parking: {
				freeParking: false,
				paidParking: false,
			},
			business: {
				conferenceHall: false,
				printing: false,
			},
			accessibility: {
				accesibleRooms: false,
			},
			wellness: {
				indoorPool: false,
				outdoorPool: false,
				heatedPool: false,
				sauna: false,
				fitness: false,
				spaCenter: false,
				ownBeach: false,
				nearBeach: false,
				beachFirstLine: false,
				beachSecondLine: false,
				aquapark: false,
				bowling: false,
				billiards: false,
			},
		},
	});

	console.log(formData);

	const translateAmenities = (key) => {
		const translations = {
			// General Amenities
			airConditioner: "Кондиционер",
			smokingAreas: "Места для курения",
			heating: "Отопление",
			elevator: "Лифт",
			garden: "Сад",

			// Rooms Amenities
			phone: "Телефон",
			TV: "Телевизор",
			smokingRooms: "Номера для курящих",
			nonSmokingRooms: "Номера для некурящих",
			slippers: "Тапочки",
			fridge: "Холодильник",
			minibar: "Мини-бар",
			freeMinibar: "Бесплатный мини-бар",
			freeWater: "Бесплатная вода",
			wardrobe: "Шкаф",
			bathrobe: "Халат",
			teapot: "Чайник",
			coffeeTea: "Чай и кофе бесплатно",
			coffeeMachine: "Кофемашина",
			safe: "Сейф",
			roomService: "Доставка еды в номер",

			// Bathroom Amenities
			ownBathroom: "Собственная ванная",
			bathroomOnFloor: "Ванная на этаже",
			ownBathroomAmenities:
				"Туалетные принадлежности в индивидуальной упаковке",
			dosatorAmenities: "Туалетные принадлежности в дозаторе",
			showerInAllRooms: "Душ во всех номерах",
			showerInPartRooms: "Душ в некоторых номерах",
			bathInAllRooms: "Ванная во всех номерах",
			bathInPartRooms: "Ванная в некоторых номерах",

			// Bedroom Amenities
			pillowChoice: "Меню подушек",

			// Services Amenities
			laundry: "Прачечная",
			selfLaundry: "Прачечная самообслуживания",
			baggageRoom: "Комната для багажа",
			ATM: "Банкомат",
			currencyExchange: "Обмен валюты",

			// Food Amenities
			bar: "Бар",
			breakfastBuffet: "Шведский стол на завтрак",
			breakfastContinental: "Континентальный завтрак",

			// Internet Amenities
			freeWifi: "Бесплатный Wi-Fi",
			paidWifi: "Платный Wi-Fi",

			// Parking Amenities
			freeParking: "Бесплатная парковка",
			paidParking: "Платная парковка",

			// Business Amenities
			conferenceHall: "Конференц-зал",
			printing: "Печать",

			// Accessibility Amenities
			accesibleRooms:
				"Специализированные номера для лиц с ограниченными возможностями",

			// Wellness Amenities
			indoorPool: "Крытый бассейн",
			outdoorPool: "Открытый бассейн",
			heatedPool: "Подогреваемый бассейн",
			sauna: "Сауна",
			fitness: "Фитнес",
			spaCenter: "СПА-центр",
			ownBeach: "Собственный пляж",
			nearBeach: "Пляж рядом",
			beachFirstLine: "Первая линия (пляж)",
			beachSecondLine: "Вторая линия (пляж)",
			aquapark: "Аквапарк",
			bowling: "Боулинг",
			billiards: "Бильярд",
		};

		return translations[key] || key; // Return the key if no translation is found
	};

	const handleCheckboxChange = (category, property) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			amenities: {
				...prevFormData.amenities,
				[category]: {
					...prevFormData.amenities[category],
					[property]: !prevFormData.amenities[category][property],
				},
			},
		}));
		handleHotelFormChange(() => formData); // Pass updated data to parent component
	};

	const [errors, setErrors] = useState({
		checkInTime: false,
		checkOutTime: false,
		numberOfFloors: false,
		numberOfRooms: false,
		yearBuilt: false,
		yearRenovated: false,
		breakfastCost: {
			price: false,
			currency: false,
		},
		breakfastTimeStart: false,
		breakfastTimeEnd: false,
	});

	const validateInput = (key, value) => {
		switch (key) {
			case "checkInTime":
			case "checkOutTime":
			case "breakfastTimeStart":
			case "breakfastTimeEnd":
				// Validate time only on blur (once user finishes typing)
				return /^([0-1]\d|2[0-3]):([0-5]\d)$/.test(value);
			case "numberOfFloors":
				return value >= 1 && value <= 200;
			case "numberOfRooms":
				return value >= 1 && value <= 5000;
			case "yearBuilt":
			case "yearRenovated":
				return value >= 1000 && value <= 9999;
			case "breakfastCost.price":
				return value >= 0 && value <= 100000000; // Price validation
			default:
				return true;
		}
	};

	const handleInputChange = (e, key, maxLength = null) => {
		const value = e.target.value;

		// Set form data without immediately validating
		if (!maxLength || value.length <= maxLength) {
			if (key.includes("breakfastCost")) {
				const field = key.split(".")[1]; // Get the specific field (price or currency)
				const updatedData = {
					...formData,
					breakfastCost: {
						...formData.breakfastCost,
						[field]: value,
					},
				};
				setFormData(updatedData);
				handleHotelFormChange(updatedData); // Pass updated data to parent component
			} else {
				const updatedData = { ...formData, [key]: value };
				setFormData(updatedData);
				handleHotelFormChange(updatedData); // Pass updated data to parent component
			}
		}
	};

	const handleBlur = (key) => {
		// Validate the input once the user has finished typing
		const isValid = validateInput(
			key,
			formData[key] || formData.breakfastCost[key.split(".")[1]]
		);
		setErrors((prevErrors) => {
			if (key.includes("breakfastCost")) {
				const field = key.split(".")[1]; // Get the specific field (price or currency)
				return {
					...prevErrors,
					breakfastCost: {
						...prevErrors.breakfastCost,
						[field]: !isValid,
					},
				};
			}
			return { ...prevErrors, [key]: !isValid };
		});
	};

	return (
		<form>
			<TextField
				label="Время заезда"
				placeholder="15:00"
				value={formData.checkInTime}
				onChange={(e) => handleInputChange(e, "checkInTime")}
				onBlur={() => handleBlur("checkInTime")} // Validate on blur
				inputProps={{
					pattern: "^([0-1]\\d|2[0-3]):([0-5]\\d)$",
				}}
				error={errors.checkInTime}
				helperText={
					errors.checkInTime ? "Неверный формат времени" : "Формат: 15:00"
				}
				fullWidth
			/>

			<TextField
				label="Время выезда"
				placeholder="12:00"
				value={formData.checkOutTime}
				onChange={(e) => handleInputChange(e, "checkOutTime")}
				onBlur={() => handleBlur("checkOutTime")} // Validate on blur
				inputProps={{
					pattern: "^([0-1]\\d|2[0-3]):([0-5]\\d)$",
				}}
				error={errors.checkOutTime}
				helperText={
					errors.checkOutTime ? "Неверный формат времени" : "Формат: 12:00"
				}
				fullWidth
			/>

			<TextField
				label="Количество этажей"
				value={formData.numberOfFloors}
				onChange={(e) => handleInputChange(e, "numberOfFloors")}
				onBlur={() => handleBlur("numberOfFloors")}
				inputProps={{
					type: "number",
					min: 1,
					max: 200,
				}}
				error={errors.numberOfFloors}
				helperText={
					errors.numberOfFloors
						? "Введите число от 1 до 200"
						: "Введите число от 1 до 200"
				}
				fullWidth
			/>

			<TextField
				label="Количество номеров"
				value={formData.numberOfRooms}
				onChange={(e) => handleInputChange(e, "numberOfRooms")}
				onBlur={() => handleBlur("numberOfRooms")}
				inputProps={{
					type: "number",
					min: 1,
					max: 5000,
				}}
				error={errors.numberOfRooms}
				helperText={
					errors.numberOfRooms
						? "Введите число от 1 до 5000"
						: "Введите число от 1 до 5000"
				}
				fullWidth
			/>

			<TextField
				label="Год постройки"
				value={formData.yearBuilt}
				onChange={(e) => handleInputChange(e, "yearBuilt", 4)}
				onBlur={() => handleBlur("yearBuilt")}
				inputProps={{
					type: "number",
					min: 1000,
					max: 9999,
				}}
				error={errors.yearBuilt}
				helperText={
					errors.yearBuilt
						? "Введите правильный год (например, 1990)"
						: "Введите 4-значный год (например, 1990)"
				}
				fullWidth
			/>

			<TextField
				label="Год реновации"
				value={formData.yearRenovated}
				onChange={(e) => handleInputChange(e, "yearRenovated", 4)}
				onBlur={() => handleBlur("yearRenovated")}
				inputProps={{
					type: "number",
					min: 1000,
					max: 9999,
				}}
				error={errors.yearRenovated}
				helperText={
					errors.yearRenovated
						? "Введите правильный год (например, 2020)"
						: "Введите 4-значный год (например, 2020)"
				}
				fullWidth
			/>

			<FormControl fullWidth>
				<InputLabel>Разрешены ли домашние животные?</InputLabel>
				<Select
					value={formData.isAllowedPets}
					onChange={(e) => handleInputChange(e, "isAllowedPets")}
				>
					<MenuItem value="true">Да</MenuItem>
					<MenuItem value="false">Нет</MenuItem>
				</Select>
			</FormControl>

			<TextField
				label="Стоимость завтрака"
				value={formData.breakfastCost.price}
				onChange={(e) => handleInputChange(e, "breakfastCost.price")}
				onBlur={() => handleBlur("breakfastCost.price")}
				inputProps={{
					type: "number",
					min: 0,
					max: 100000000,
				}}
				error={errors.breakfastCost.price}
				helperText={
					errors.breakfastCost.price
						? "Введите цену от 0 до 100000000"
						: "Введите цену от 0 до 100000000"
				}
				fullWidth
			/>

			<FormControl fullWidth>
				<InputLabel>Валюта</InputLabel>
				<Select
					value={formData.breakfastCost.currency}
					onChange={(e) => handleInputChange(e, "breakfastCost.currency")}
				>
					<MenuItem value="RUB">Российский рубль (RUB)</MenuItem>
					<MenuItem value="EUR">Евро (EUR)</MenuItem>
					<MenuItem value="USD">Американский доллар (USD)</MenuItem>
				</Select>
			</FormControl>

			<TextField
				label="Время начала завтрака"
				placeholder="07:00"
				value={formData.breakfastTimeStart}
				onChange={(e) => handleInputChange(e, "breakfastTimeStart")}
				onBlur={() => handleBlur("breakfastTimeStart")} // Validate on blur
				inputProps={{
					pattern: "^([0-1]\\d|2[0-3]):([0-5]\\d)$",
				}}
				error={errors.breakfastTimeStart}
				helperText={
					errors.breakfastTimeStart
						? "Неверный формат времени"
						: "Формат: 07:00"
				}
				fullWidth
			/>

			<TextField
				label="Время окончания завтрака"
				placeholder="10:00"
				value={formData.breakfastTimeEnd}
				onChange={(e) => handleInputChange(e, "breakfastTimeEnd")}
				onBlur={() => handleBlur("breakfastTimeEnd")} // Validate on blur
				inputProps={{
					pattern: "^([0-1]\\d|2[0-3]):([0-5]\\d)$",
				}}
				error={errors.breakfastTimeEnd}
				helperText={
					errors.breakfastTimeEnd ? "Неверный формат времени" : "Формат: 10:00"
				}
				fullWidth
			/>
			{/* Amenities Checkboxes */}
			<Typography variant="h6">Услуги отеля</Typography>

			{/* General Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Общие услуги</Typography>
			{Object.entries(formData.amenities.general).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("general", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Rooms Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Услуги номеров</Typography>
			{Object.entries(formData.amenities.rooms).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("rooms", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Bathroom Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Услуги в ванной</Typography>
			{Object.entries(formData.amenities.bathroom).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("bathroom", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Bedroom Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Услуги в спальне</Typography>
			{Object.entries(formData.amenities.bedroom).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("bedroom", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Services Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Сервисы</Typography>
			{Object.entries(formData.amenities.services).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("services", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Food Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Питание</Typography>
			{Object.entries(formData.amenities.food).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("food", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Internet Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Интернет</Typography>
			{Object.entries(formData.amenities.internet).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("internet", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Parking Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Парковка</Typography>
			{Object.entries(formData.amenities.parking).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("parking", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Business Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Бизнес</Typography>
			{Object.entries(formData.amenities.business).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("business", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Accessibility Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Доступность</Typography>
			{Object.entries(formData.amenities.accessibility).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("accessibility", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}

			{/* Wellness Amenities */}
			<Typography style={{ fontWeight: "bold" }}>Отдых и активности</Typography>
			{Object.entries(formData.amenities.wellness).map(([key, value]) => (
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							checked={value}
							onChange={() => handleCheckboxChange("wellness", key)}
						/>
					}
					label={translateAmenities(key)} // Use the translation function here
				/>
			))}
		</form>
	);
};

export default HotelForm;
