export const ERROR = "ERROR";

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const DELETE_POST = "DELETE_POST";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_RANDOM = "FETCH_RANDOM";
export const FETCH_POST = "FETCH_POST";
export const FETCH_REPORT_POSTS = "FETCH_REPORT_POSTS";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const FETCH_POST_CONTENT = "FETCH_POST_CONTENT";
export const FETCH_ADJ_POST = "FETCH_ADJ_POST";
export const LIKE = "LIKE";
export const COMMENT = "COMMENT";
export const SEND_RESPONSE_COMMENT_REPORT = "SEND_RESPONSE_COMMENT_REPORT";
export const SEND_RESPONSE_COMMENT_POST = "SEND_RESPONSE_COMMENT_POST";
export const ADD_POST = "ADD_POST";
export const FETCH_POSTS_BY_USER = "FETCH_POSTS_BY_USER";
export const LIKE_POST = "LIKE_POST";
export const LIKE_COMMENT_POST = "LIKE_COMMENT_POST";
export const LIKE_POST_IN_REPORT = "LIKE_POST_IN_REPORT";
export const LIKE_COMMENT_REPORT = "LIKE_COMMENT_REPORT";
export const FETCH_UPDATED_POST = "FETCH_UPDATED_POST";
export const ADD_YANDEX_IMAGE = "ADD_YANDEX_IMAGE";
export const DELETE_YANDEX_IMAGE = "DELETE_YANDEX_IMAGE";
export const GET_POST_SMALL = "GET_POST_SMALL";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const START_LOADING_PHOTO = "START_LOADING_PHOTO";
export const END_LOADING_PHOTO = "END_LOADING_PHOTO";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_SIGNUP = "AUTH_SIGNUP";
export const LOGOUT = "LOGOUT";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_COUNTRIES = "EDIT_COUNTRIES";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_IMG = "FETCH_USER_IMG";
export const START_LOADING_USER = "START_LOADING_USER";
export const END_LOADING_USER = "END_LOADING_USER";
export const START_LOADING_AVATAR = "START_LOADING_AVATAR";
export const END_LOADING_AVATAR = "END_LOADING_AVATAR";
export const ADD_YANDEX_AVATAR = "ADD_YANDEX_AVATAR";
export const GET_NUMBER_USERS = "GET_NUMBER_USERS";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER_SUBSCRIBERS = "FETCH_USER_SUBSCRIBERS";
export const FETCH_USER_FRIENDS = "FETCH_USER_FRIENDS";

export const START_LOADING_MAIN = "START_LOADING_MAIN";
export const END_LOADING_MAIN = "END_LOADING_MAIN";

export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const SEND_CHANGE_PASSWORD = "SEND_CHANGE_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_UNVIEWED = "GET_UNVIEWED";
export const GET_UNVIEWED_LIKES = "GET_UNVIEWED_LIKES";
export const GET_UNVIEWED_COMMENTSPOST = "GET_UNVIEWED_COMMENTSPOST";
export const GET_UNVIEWED_COMMENTSREPORT = "GET_UNVIEWED_COMMENTSREPORT";
export const GET_UNVIEWED_RESPONSECOMMENT = "GET_UNVIEWED_RESPONSECOMMENT";
export const GET_UNVIEWED_SUBSCRIBE = "GET_UNVIEWED_SUBSCRIBE";
export const START_LOADING_NOTIFICATIONS = "START_LOADING_NOTIFICATIONS";
export const END_LOADING_NOTIFICATIONS = "END_LOADING_NOTIFICATIONS";
export const START_LOADING_UNVIEWED = "START_LOADING_UNVIEWED";
export const END_LOADING_UNVIEWED = "END_LOADING_UNVIEWED";
export const GET_MESSAGE_NOTIFICATIONS = "GET_MESSAGE_NOTIFICATIONS";
export const ACCEPT_FRIEND_FROM_NOTIFICATION =
	"ACCEPT_FRIEND_FROM_NOTIFICATION";

export const ADD_ALERT = "ADD_ALERT";
export const DELETE_ALL_ALERTS = "DELETE_ALL_ALERTS";
export const ADD_LAST_PHOTOS = "ADD_LAST_PHOTOS";
export const ADD_LAST_COMMENTS = "ADD_LAST_COMMENTS";
export const ADD_LAST_PLACES = "ADD_LAST_PLACES";

export const ADD_PLACE = "ADD_PLACE";
export const GET_PLACE = "GET_PLACE";
export const ADD_POST_TO_PLACE = "ADD_POST_TO_PLACE";
export const ADD_CITY_TO_PLACE = "ADD_CITY_TO_PLACE";

export const GET_PLACES = "GET_PLACES";
export const GET_PLACES_BY_CITY = "GET_PLACES_BY_CITY";
export const GET_PLACES_BY_TYPE = "GET_PLACES_BY_TYPE";
export const CHECK_PLACES_EXIST = "CHECK_PLACES_EXIST";
export const START_LOADING_PLACES = "START_LOADING_PLACES";
export const END_LOADING_PLACES = "END_LOADING_PLACES";
export const START_LOADING_PLACES_SEARCH = "START_LOADING_PLACES_SEARCH";
export const END_LOADING_PLACES_SEARCH = "END_LOADING_PLACES_SEARCH";
export const SEARCH_PLACES = "SEARCH_PLACES";
export const START_LOADING_NEAREST_PLACES = "START_LOADING_NEAREST_PLACES";
export const GET_NEAREST_PLACES = "GET_NEAREST_PLACES";
export const END_LOADING_NEAREST_PLACES = "END_LOADING_NEAREST_PLACES";
export const START_LOADING_PLACES_BY_IDS = "START_LOADING_PLACES_BY_IDS";
export const GET_PLACES_BY_IDS = "GET_PLACES_BY_IDS";
export const END_LOADING_PLACES_BY_IDS = "END_LOADING_PLACES_BY_IDS";

export const ADD_PLACE_DESCRIPTION = "ADD_PLACE_DESCRIPTION";
export const GET_PLACE_DESCRIPTION = "GET_PLACE_DESCRIPTION";
export const VERIFY_PLACE_DESCRIPTION = "VERIFY_PLACE_DESCRIPTION";

export const START_LOADING_FEED = "START_LOADING_FEED";
export const END_LOADING_FEED = "END_LOADING_FEED";
export const FETCH_POSTS_FEED = "FETCH_POSTS_FEED";
export const FETCH_MORE_POSTS = "FETCH_MORE_POSTS";
export const START_LOADING_MORE = "START_LOADING_MORE";
export const END_LOADING_MORE = "END_LOADING_MORE";

export const CREATE_PLACE_REVIEW = "CREATE_PLACE_REVIEW";
export const FETCH_PLACE_REVIEWS = "FETCH_PLACE_REVIEWS";

export const CREATE_PLACE_SELECTION = "CREATE_PLACE_SELECTION";
export const FETCH_PLACE_SELECTIONS = "FETCH_PLACE_SELECTIONS";
export const GET_PLACE_SELECTION_BY_ID = "GET_PLACE_SELECTION_BY_ID";
export const ADD_CITY_TO_PLACE_SELECTION = "ADD_CITY_TO_PLACE_SELECTION";

export const GET_PHOTO_BY_URL = "GET_PHOTO_BY_URL";
