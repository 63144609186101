import {
	GET_NOTIFICATIONS,
	START_LOADING_NOTIFICATIONS,
	END_LOADING_NOTIFICATIONS,
	GET_UNVIEWED_COMMENTSPOST,
	GET_UNVIEWED_COMMENTSREPORT,
	GET_UNVIEWED_LIKES,
	GET_UNVIEWED_RESPONSECOMMENT,
	GET_UNVIEWED,
	START_LOADING_UNVIEWED,
	END_LOADING_UNVIEWED,
	GET_MESSAGE_NOTIFICATIONS,
	GET_UNVIEWED_SUBSCRIBE,
} from "../constants/actionTypes";

const notificationsReducer = (
	state = {
		isLoading: true,
		isLoadingUnviewed: true,
		notificationsList: [],
		unviewed: 0,
		unviewedLikes: 0,
		unviewedCommentsPost: 0,
		unviewedCommentsReport: 0,
		unviewedResponseComment: 0,
		unviewedSubscribe: 0,
		numberOfPages: 0,
	},
	action
) => {
	switch (action.type) {
		case START_LOADING_NOTIFICATIONS:
			return { ...state, isLoading: true };
		case END_LOADING_NOTIFICATIONS:
			return { ...state, isLoading: false };
		case START_LOADING_UNVIEWED:
			return { ...state, isLoadingUnviewed: true };
		case END_LOADING_UNVIEWED:
			return { ...state, isLoadingUnviewed: false };
		case GET_NOTIFICATIONS:
			return {
				...state,
				notificationsList: action.payload.data,
				numberOfPages: action.payload.numberOfPages,
			};
		case GET_UNVIEWED:
			return {
				...state,
				unviewed: action.payload,
			};
		case GET_UNVIEWED_LIKES:
			return {
				...state,
				unviewedLikes: action.payload,
			};
		case GET_UNVIEWED_COMMENTSPOST:
			return {
				...state,
				unviewedCommentsPost: action.payload,
			};
		case GET_UNVIEWED_COMMENTSREPORT:
			return {
				...state,
				unviewedCommentsReport: action.payload,
			};
		case GET_UNVIEWED_RESPONSECOMMENT:
			return {
				...state,
				unviewedResponseComment: action.payload,
			};
		case GET_UNVIEWED_SUBSCRIBE:
			return {
				...state,
				unviewedSubscribe: action.payload,
			};
		case GET_MESSAGE_NOTIFICATIONS:
			return state;
		default:
			return state;
	}
};

export default notificationsReducer;
