import React from "react";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	Grid,
} from "@material-ui/core";
import {
	switchAmenitiesImage,
	translateAmenities,
} from "../../../../utils/placeUtils";

const Amenities = ({ amenities }) => {
	if (!amenities) return null;

	const renderAmenitiesList = (categoryName, category) => {
		const translatedCategory = translateAmenities(categoryName);
		const amenitiesList = Object.keys(category)
			.filter((amenity) => category[amenity]) // Only include true values
			.map((amenity) => translateAmenities(amenity)); // Translate the amenity

		if (amenitiesList.length === 0) return null; // Don't render if no amenities are true

		return (
			<div key={categoryName}>
				<div style={{ display: "flex" }}>
					<img
						width="24"
						height="24"
						src={switchAmenitiesImage(categoryName)}
						alt="no image"
					/>
					<Typography
						variant="subtitle1"
						component="subtitle1"
						style={{ fontWeight: "bold" }}
					>
						{translatedCategory}
					</Typography>
				</div>
				<List style={{ paddingTop: "3px", paddingBottom: "3px" }}>
					{amenitiesList.map((translatedAmenity, index) => (
						<ListItem
							key={index}
							style={{ paddingTop: "3px", paddingBottom: "3px" }}
						>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/checkmark.png"
								alt="checkmark"
							/>
							<ListItemText primary={translatedAmenity} />
						</ListItem>
					))}
				</List>
			</div>
		);
	};

	// Manual assignment of categories to columns
	const column1 = ["general", "rooms"];
	const column2 = [
		"bathroom",
		"bedroom",
		"services",
		"food",
		"internet",
		"parking",
	];
	const column3 = ["business", "accessibility", "wellness"];

	return (
		<>
			<Typography variant="h6" gutterBottom>
				Удобства и услуги в отеле
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} lg={4}>
					{column1.map((category) =>
						amenities[category]
							? renderAmenitiesList(category, amenities[category])
							: null
					)}
				</Grid>
				<Grid item xs={12} sm={12} lg={4}>
					{column2.map((category) =>
						amenities[category]
							? renderAmenitiesList(category, amenities[category])
							: null
					)}
				</Grid>
				<Grid item xs={12} sm={12} lg={4}>
					{column3.map((category) =>
						amenities[category]
							? renderAmenitiesList(category, amenities[category])
							: null
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default Amenities;
