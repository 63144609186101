import * as React from "react";
import {
	Typography,
	Container,
	Link,
	Grid,
	Box,
	Divider,
} from "@material-ui/core";
import useStyles from "./styles.js";
import totravel from "../../images/totravel.png";
import boosty from "../../images/boosty.png";
import vk from "../../images/vk.png";
import telegram from "../../images/telegram.png";
import youtube from "../../images/youtube.png";

const Footer = () => {
	const classes = useStyles();
	return (
		<Box component="footer" className={classes.footer}>
			<Divider variant="middle" className={classes.divider} />
			<Container maxWidth="xl" className={classes.footerContainer}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Link
							href="https://totravelru.ru/"
							className={classes.brandContainer}
							underline="none"
						>
							<Typography
								className={classes.heading}
								variant="h4"
								align="center"
							>
								to
							</Typography>
							<Typography
								className={classes.heading_black}
								variant="h4"
								align="center"
							>
								travel
							</Typography>
							<img
								className={classes.image}
								src={totravel}
								alt="totravel"
								height="40px"
							/>
						</Link>
						<Link
							href="/support"
							className={classes.brandContainerSupport}
							underline="none"
						>
							<img src={boosty} alt="" className={classes.boostyImg} />
							<Typography variant="body1" className={classes.heading_support}>
								Поддержать проект
							</Typography>
						</Link>
					</Grid>
					<Grid item xs={12} sm={4} className={classes.gridElement}>
						<Typography variant="h6" color="text.primary" gutterBottom>
							Контакты
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Бот Telegram: @totravelrubot
						</Typography>
						<Typography variant="body2" color="text.secondary">
							E-mail: feedback@totravelru.ru
						</Typography>
						<Link href="/agreement" color="text.primary" gutterBottom>
							<Typography variant="body2">
								Пользовательское соглашение
							</Typography>
						</Link>
						<Link href="/privacy" color="text.primary" gutterBottom>
							<Typography variant="body2">
								Политика в отношении обработки персональных данных
							</Typography>
						</Link>
						<Link href="/content-claims" color="text.primary" gutterBottom>
							<Typography variant="body2">
								Интеллектуальные права и претензии
							</Typography>
						</Link>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography variant="h6" color="text.primary" gutterBottom>
							Подписывайтесь
						</Typography>
						<Link
							href="https://www.vk.com/totravelru"
							color="inherit"
							target="_blank"
						>
							<img src={vk} alt="" className={classes.socialImg} />
						</Link>
						<Link
							href="https://t.me/totravelru"
							color="inherit"
							sx={{ pl: 1, pr: 1 }}
							className={classes.linkTelegram}
							target="_blank"
						>
							<img src={telegram} alt="" className={classes.socialImg} />
						</Link>
						<Link
							href="https://www.youtube.com/@ToTravelRu"
							color="inherit"
							sx={{ pl: 1, pr: 1 }}
							className={classes.linkTelegram}
							target="_blank"
						>
							<img src={youtube} alt="" className={classes.socialImg} />
						</Link>
					</Grid>
				</Grid>
				<Box mt={3}>
					<Typography variant="body2" color="text.secondary" align="center">
						{"Все права защищены © "}
						<Link color="inherit" href="https://totravelru.ru/">
							Totravel
						</Link>{" "}
						{new Date().getFullYear()}
					</Typography>
				</Box>
				<Box>
					<Typography variant="body2" color="text.secondary" align="center">
						Icons by{" "}
						<a className={classes.icons8} href="https://icons8.com">
							icons8
						</a>
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default Footer;
