import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	createContainer: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		backgroundColor: "#fff",
		borderRadius: "10px",
		boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
	},
	inputField: {
		marginBottom: theme.spacing(2),
	},
	saveButton: {
		marginTop: theme.spacing(1),
		borderRadius: "20px",
		backgroundColor: "rgba(236, 98, 98, 1)",
		color: "rgba(255,255,255,1)",
		padding: theme.spacing(1, 4),
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	cancelButton: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		borderRadius: "20px",
		color: "rgba(236,98,98,1)",
		borderColor: "rgba(236,98,98,1)",
		padding: theme.spacing(1, 3),
		"&:hover": {
			backgroundColor: "rgba(236,98,98,0.1)",
		},
	},
	gridContainer: {
		marginTop: "20px",
	},
}));

export default useStyles;
