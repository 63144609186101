import React, { useEffect } from "react";
import {
	Avatar,
	Paper,
	Typography,
	Container,
	Button,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { confirmEmail } from "../../actions/auth.js";

import useStyles from "./styles";

const Verify = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const tokenName = window.location.pathname.split("tokenVerify/")[1];

	useEffect(() => {
		dispatch(confirmEmail(tokenName));
	}, []);

	const successVerify = useSelector((state) => state.auth.msg);

	const isSuccessVerify = successVerify?.status === 200;

	return (
		<Container component="main" maxWidth="xs">
			<Paper className={classes.paper} elevation={3}>
				<Avatar className={classes.avatar}>
					{isSuccessVerify ? <LockOpenIcon /> : <LockOutlinedIcon />}
				</Avatar>
				{isSuccessVerify ? (
					<>
						<Typography variant="h5">Аккаунт подтвержден</Typography>
						<Typography className={classes.textEmail} variant="subtitle1">
							Нажмите на кнопку "Войти"
						</Typography>
						<Button
							component={Link}
							to="/auth"
							variant="contained"
							className={classes.buttonPrimary}
						>
							Войти
						</Button>
					</>
				) : (
					<>
						<Typography variant="h5">Возникла ошибка</Typography>
						<Typography className={classes.textEmail} variant="subtitle1">
							Попробуйте подтвердить аккаунт еще раз
						</Typography>
					</>
				)}
			</Paper>
		</Container>
	);
};

export default Verify;
