import React, { useState, useEffect } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import SuggestHowToGet from "./SuggestHowToGet";
import parse from "html-react-parser";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getPlacesByIds } from "../../../../actions/places";
import PlaceCard from "../PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard";

const HowToGet = ({ place, userId }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const placesByIds = useSelector((state) => state.place?.placesByIds);
	let placesIds;

	console.log(placesByIds);

	useEffect(() => {
		const ids = [];

		if (place?.howToGet?.nearAvia?.length > 0) {
			ids.push(...place.howToGet.nearAvia);
		}

		if (place?.howToGet?.nearTrain?.length > 0) {
			ids.push(...place.howToGet.nearTrain);
		}

		if (place?.howToGet?.nearPort?.length > 0) {
			ids.push(...place.howToGet.nearPort);
		}

		if (place?.howToGet?.nearBus?.length > 0) {
			ids.push(...place.howToGet.nearBus);
		}

		if (ids.length > 0) {
			placesIds = ids.join(",");
			dispatch(getPlacesByIds(placesIds));
		}
	}, [place, dispatch]);

	const toggleDetails = () => {
		setShowDetails(!showDetails);
	};

	return (
		<div>
			<Typography variant="h6">
				Как добраться до города {place?.placeName}?
			</Typography>

			<div style={{ marginTop: "10px" }}>
				{place?.howToGet?.descriptionHowToGet && (
					<div className={classes.textWrap}>
						{parse(place.howToGet.descriptionHowToGet)}
					</div>
				)}
				{place?.howToGet?.avia && (
					<Typography variant="body1">
						<strong>Самолетом </strong>{" "}
						<div className={classes.textWrap}>{parse(place.howToGet.avia)}</div>
					</Typography>
				)}
				{place?.howToGet?.nearAvia.length > 0 && (
					<>
						<Typography variant="body1">
							<strong>Ближайшие аэропорты </strong>{" "}
						</Typography>
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={2}
							className={classes.gridContainer}
						>
							{placesByIds
								?.filter((p) => p?.placeType === "airport")
								?.map((p) => (
									<Grid item xs={12} sm={6} md={4}>
										<PlaceCard key={p._id} p={p} />
									</Grid>
								))}
						</Grid>
					</>
				)}
				{place?.howToGet?.train && (
					<Typography variant="body1">
						<strong>Поездом </strong>{" "}
						<div className={classes.textWrap}>
							{parse(place.howToGet.train)}
						</div>
					</Typography>
				)}
				{place?.howToGet?.nearTrain.length > 0 && (
					<>
						<Typography variant="body1">
							<strong>Вокзалы города {place?.placeName} </strong>{" "}
						</Typography>
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={2}
							className={classes.gridContainer}
						>
							{placesByIds
								?.filter((p) => p?.placeType === "railway")
								?.map((p) => (
									<Grid item xs={12} sm={6} md={4}>
										<PlaceCard key={p._id} p={p} />
									</Grid>
								))}
						</Grid>
					</>
				)}
				{place?.howToGet?.sea && (
					<Typography variant="body1">
						<strong>Морем </strong>{" "}
						<div className={classes.textWrap}>{parse(place.howToGet.sea)}</div>
					</Typography>
				)}
				{place?.howToGet?.nearPort.length > 0 && (
					<>
						<Typography variant="body1">
							<strong>Порты города {place?.placeName} </strong>{" "}
						</Typography>
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={2}
							className={classes.gridContainer}
						>
							{placesByIds
								?.filter((p) => p?.placeType === "railway")
								?.map((p) => (
									<Grid item xs={12} sm={6} md={4}>
										<PlaceCard key={p._id} p={p} />
									</Grid>
								))}
						</Grid>
					</>
				)}
				{place?.howToGet?.bus && (
					<Typography variant="body1">
						<strong>Автобусом </strong>{" "}
						<div className={classes.textWrap}>{parse(place.howToGet.bus)}</div>
					</Typography>
				)}
				{place?.howToGet?.road && (
					<Typography variant="body1">
						<strong>На машине </strong>{" "}
						<div className={classes.textWrap}>{parse(place.howToGet.road)}</div>
					</Typography>
				)}
			</div>
			{userId && (
				<Button
					variant="contained"
					className={classes.buttonPrimary}
					onClick={toggleDetails}
				>
					{showDetails ? "Закрыть" : "Добавить информацию"}
				</Button>
			)}

			{showDetails && (
				<SuggestHowToGet
					place={place}
					setShowDetails={setShowDetails}
					userId={userId}
				/>
			)}
		</div>
	);
};

export default HowToGet;
