import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { getLastPlaces } from "../../../actions/admin";
import { Link } from "react-router-dom"; // Import Link
import PlaceCard from "../../Places/Place/PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard";

const LastPlaces = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const lastPlaces = useSelector((state) => state?.admin?.lastPlaces); // Accessing lastPhotos from Redux state

	const handleLoadLastPlaces = () => {
		dispatch(getLastPlaces());
	};

	return (
		<div>
			<Typography variant="h5">Последние места</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={handleLoadLastPlaces}
			>
				Загрузить последние места
			</Button>
			<Grid container spacing={2} className={classes.gridContainer}>
				{lastPlaces && lastPlaces.length > 0 ? (
					lastPlaces.map((place) => (
						<Grid item xs={12} sm={6} md={4} key={place._id}>
							<PlaceCard p={place} />
						</Grid>
					))
				) : (
					<Typography>Фотографии отсутствуют</Typography>
				)}
			</Grid>
		</div>
	);
};

export default LastPlaces;
