import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	flex: {
		display: "flex",
	},
	descriptionTitle: {
		fontWeight: "600",
	},
}));
