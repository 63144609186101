import {
	FETCH_ALL,
	FETCH_POST,
	CREATE,
	UPDATE,
	DELETE,
	DELETE_POST,
	LIKE,
	FETCH_BY_SEARCH,
	FETCH_REPORT_POSTS,
	FETCH_POST_CONTENT,
	START_LOADING,
	END_LOADING,
	COMMENT,
	ADD_POST,
	FETCH_ADJ_POST,
	FETCH_POSTS_BY_USER,
	LIKE_POST,
	LIKE_COMMENT_POST,
	LIKE_POST_IN_REPORT,
	LIKE_COMMENT_REPORT,
	FETCH_UPDATED_POST,
	ADD_YANDEX_IMAGE,
	START_LOADING_PHOTO,
	END_LOADING_PHOTO,
	SEND_RESPONSE_COMMENT_REPORT,
	SEND_RESPONSE_COMMENT_POST,
	DELETE_YANDEX_IMAGE,
	GET_POST_SMALL,
} from "../constants/actionTypes";
import * as api from "../api";

export const getPosts = (page) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchPosts(page);
		dispatch({ type: FETCH_ALL, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const {
			data: { data },
		} = await api.fetchPostsBySearch(searchQuery);

		dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
	}
};

export const getPostsByUser = (login, page) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchPostsByUser(login, page);
		dispatch({ type: FETCH_POSTS_BY_USER, payload: { data } });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const createPost = (post, history) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.createPost(post);
		history.push(`/posts/${data._id}`);

		dispatch({ type: CREATE, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const updatePost = (id, post) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.updatePost(id, post);
		dispatch({ type: UPDATE, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
	}
};

export const updatePostReport = (id, post) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.updatePostReport(id, post);
		dispatch({ type: FETCH_UPDATED_POST, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
	}
};

export const deletePost = (id) => async (dispatch) => {
	try {
		await api.deletePost(id);

		dispatch({ type: DELETE, payload: id });
	} catch (error) {
		console.log(error);
	}
};

export const deletePostReport = (id) => async (dispatch) => {
	try {
		await api.deletePostReport(id);

		dispatch({ type: DELETE_POST, payload: id });
	} catch (error) {
		console.log(error);
	}
};

export const likePost = (id) => async (dispatch) => {
	try {
		const { data } = await api.likePost(id);
		dispatch({ type: LIKE, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const likePostReport = (id) => async (dispatch) => {
	try {
		const { data } = await api.likePostReport(id);
		dispatch({ type: LIKE_POST, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const likeCommentReport = (value) => async (dispatch) => {
	try {
		const reportId = value.reportId;
		const { data } = await api.likeCommentReport(value, reportId);
		dispatch({ type: LIKE_COMMENT_REPORT, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const likeCommentPost = (value) => async (dispatch) => {
	try {
		const postId = value.postId;
		const { data } = await api.likeCommentPost(value, postId);
		dispatch({ type: LIKE_COMMENT_POST, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const commentPost = (value, id) => async (dispatch) => {
	try {
		const { data } = await api.comment(value, id);
		dispatch({ type: COMMENT, payload: data });

		return data.comments;
	} catch (error) {
		console.log(error.message);
	}
};

export const sendResponseCommentReport = (value, id) => async (dispatch) => {
	try {
		const { data } = await api.sendResponseCommentReport(value, id);
		dispatch({ type: SEND_RESPONSE_COMMENT_REPORT, payload: data });

		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const commentPostReport = (value, id) => async (dispatch) => {
	try {
		const { data } = await api.commentPost(value, id);
		dispatch({ type: COMMENT, payload: data });

		return data.comments;
	} catch (error) {
		console.log(error.message);
	}
};

export const sendResponseCommentPost = (value, id) => async (dispatch) => {
	try {
		const { data } = await api.sendResponseCommentPost(value, id);
		dispatch({ type: SEND_RESPONSE_COMMENT_POST, payload: data });

		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const getPost = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchPost(id);
		dispatch({ type: FETCH_POST, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const addYandexImage = (image) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PHOTO });
		const { data } = await api.addYandexImage(image);
		dispatch({ type: ADD_YANDEX_IMAGE, payload: data });
		dispatch({ type: END_LOADING_PHOTO });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const addYandexImageReport = (image) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PHOTO });
		const { data } = await api.addYandexImageReport(image);
		dispatch({ type: ADD_YANDEX_IMAGE, payload: data });
		dispatch({ type: END_LOADING_PHOTO });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteYandexImage = (path) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PHOTO });
		const { data } = await api.deleteYandexImage(path);
		dispatch({ type: DELETE_YANDEX_IMAGE, payload: data });
		dispatch({ type: END_LOADING_PHOTO });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const addNewPost = (value, id, history) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.addNewPost(value, id);
		dispatch({ type: ADD_POST, payload: data });
		dispatch({ type: END_LOADING });
		history.push(`/post/${data?._id}`);
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const getReportPosts = (page) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchReportPosts(page);
		dispatch({ type: FETCH_REPORT_POSTS, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPostContent = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchPostContent(id);
		dispatch({ type: FETCH_POST_CONTENT, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPreviousPost = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchPreviousPost(id);
		dispatch({ type: FETCH_ADJ_POST, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const getNextPost = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.fetchNextPost(id);
		dispatch({ type: FETCH_ADJ_POST, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const likePostInReport = (value) => async (dispatch) => {
	try {
		const reportId = value.reportId;
		const { data } = await api.likePostInReport(value, reportId);
		dispatch({ type: LIKE_POST_IN_REPORT, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getPostSmall = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const { data } = await api.getPostSmall(id);
		dispatch({ type: GET_POST_SMALL, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};
