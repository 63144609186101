import React, { useState } from "react";
import {
	Typography,
	Box,
	Button,
	TextField,
	IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close"; // Import the Close icon
import useStyles from "./styles.js";
import { addClaim } from "../../actions/claim.js";

const AddClaim = ({
	setReportOpen,
	postId,
	reportId,
	placeId,
	placeReviewId,
	photoUrl,
	userId,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [malignChoose, setMalignChoose] = useState("");
	const [description, setDescription] = useState("");

	const handleSubmit = async () => {
		const claim = {
			malignChoose,
			description,
			createdAt: new Date().toISOString(),
			...(postId && { postId }), // Add postId if defined and not empty
			...(reportId && { reportId }), // Add reportId if defined and not empty
			...(placeId && { placeId }), // Add placeId if defined and not empty
			...(placeReviewId && { placeReviewId }), // Add placeReviewId if defined and not empty
			...(photoUrl && { photoUrl }), // Add photoUrl if defined and not empty
			...(userId && { userId }), // Add photoUrl if defined and not empty
		};

		await dispatch(addClaim(claim));
		console.log("Report submitted", claim);
		setReportOpen(false);
	};

	const handleClose = () => {
		setReportOpen(false); // Close the report form
	};

	return (
		<Box
			style={{
				position: "fixed",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				backgroundColor: "white",
				padding: "24px",
				zIndex: 1000,
				boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
				borderRadius: "8px",
				width: "80%",
				maxWidth: "500px",
			}}
		>
			<IconButton
				style={{ position: "absolute", top: 8, right: 8 }}
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>

			<Typography variant="h6">
				Что именно вам кажется недопустимым в этом материале?
			</Typography>

			<Box mt={2}>
				<Button
					variant={malignChoose === "spam" ? "contained" : "outlined"}
					onClick={() => setMalignChoose("spam")}
					style={{ marginRight: "10px", marginTop: "5px" }}
				>
					Спам
				</Button>
				<Button
					variant={malignChoose === "violationRules" ? "contained" : "outlined"}
					onClick={() => setMalignChoose("violationRules")}
					style={{ marginRight: "10px", marginTop: "5px" }}
				>
					Нарушение правил сайта
				</Button>
				<Button
					variant={malignChoose === "violationIP" ? "contained" : "outlined"}
					onClick={() => setMalignChoose("violationIP")}
					style={{ marginTop: "5px" }}
				>
					Нарушение интеллектуальных прав
				</Button>
			</Box>

			{malignChoose && (
				<Box mt={2}>
					<TextField
						label="Опишите нарушение"
						variant="outlined"
						fullWidth
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						multiline
						minRows={4}
						inputProps={{ maxLength: 1000 }}
					/>
					<Box mt={2}>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmit}
							disabled={!malignChoose || !description} // Disable if malignChoose or description is empty
							className={classes.buttonPrimary}
						>
							Отправить жалобу
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default AddClaim;
