import React from "react";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	CardActionArea,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton"; // Import Skeleton component
import useStyles from "./styles.js";

const PlaceCardSkeleton = ({ cardType }) => {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardActionArea>
				<CardContent className={classes.CardContent}>
					<div className={classes.flexHead}>
						{cardType === "placeInSelection" ||
						cardType === "selection" ||
						cardType === "large" ? (
							<div className={classes.mediaBox}>
								<Skeleton
									variant="rect"
									className={classes.media}
									animation="wave"
								/>
							</div>
						) : null}
						<div
							className={
								cardType === "placeInSelection"
									? classes.titleBoxSelection
									: classes.titleBox
							}
						>
							<Typography className={classes.title}>
								<Skeleton width="60%" height={24} animation="wave" />
							</Typography>
							{cardType !== "selection" && (
								<div className={classes.rating}>
									<div className={classes.ratingFlex}>
										<Skeleton variant="circle" width={20} height={20} />
										<Skeleton width={30} height={24} animation="wave" />
									</div>
									<Skeleton width={60} height={20} animation="wave" />
								</div>
							)}
							<Skeleton width="100%" height={40} animation="wave" />
						</div>
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default PlaceCardSkeleton;
