import {
	START_LOADING_FEED,
	FETCH_POSTS_FEED,
	END_LOADING_FEED,
	FETCH_MORE_POSTS,
	START_LOADING_MORE,
	END_LOADING_MORE,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const fetchPostsFeed = (sort) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_FEED });
		const { data } = await api.fetchPostsFeed(sort);
		dispatch({ type: FETCH_POSTS_FEED, payload: data });
		dispatch({ type: END_LOADING_FEED });
	} catch (error) {
		console.log(error.message);
	}
};

export const fetchPostsFeedFriend = (userId) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_FEED });
		const { data } = await api.fetchPostsFeedFriend(userId);
		dispatch({ type: FETCH_POSTS_FEED, payload: data });
		dispatch({ type: END_LOADING_FEED });
	} catch (error) {
		console.log(error.message);
	}
};

export const fetchMorePostsFeed = (lastPostIndex, sort) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_MORE });
		const { data } = await api.fetchMorePostsFeed(lastPostIndex, sort);
		dispatch({ type: FETCH_MORE_POSTS, payload: data });
		dispatch({ type: END_LOADING_MORE });
	} catch (error) {
		console.log(error.message);
	}
};
