import { GET_PHOTO_BY_URL } from "../constants/actionTypes";
import * as api from "../api/index.js";

export const getPhotoByUrl = (url, id) => async (dispatch) => {
	try {
		const { data } = await api.getPhotoByUrl(url);
		dispatch({ type: GET_PHOTO_BY_URL, payload: { data, id } });
	} catch (error) {
		console.log(error.message);
	}
};
