import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	selectionContainer: {
		//padding: theme.spacing(2),
		//backgroundColor: "#f9f9f9",
		borderRadius: "10px",
		//boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
	},
	createButton: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "5px 10px",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	titleButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexWrap: "wrap",
	},
}));

export default useStyles;
