// ConfirmBox.js
import React, { useState } from "react";
import {
	Typography,
	TextField,
	Button,
	Select,
	MenuItem,
	makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	wrapBox: { display: "flex", flexDirection: "column", alignItems: "center" },
	confirmBox: {
		backgroundColor: "#fff",
		borderRadius: 15,
		border: "1px solid",
		borderColor: "rgba(236, 98, 98, 1)",
		minWidth: "250px",
		padding: 5,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	confirmBoxButton: {
		backgroundColor: "rgba(236, 98, 98, 1)",
		borderRadius: 15,
		padding: "3px 10px",
		color: "#fff",
		textDecoration: "none",
		border: "none",
		cursor: "pointer",
	},
	input: {
		width: "100%",
		marginBottom: theme.spacing(1),
	},
	select: {
		width: "100%",
		marginBottom: theme.spacing(1),
	},
	imagePoint: {
		width: "35px",
	},
}));

const ConfirmBox = ({ handleConfirm, chosenCoords, postContent }) => {
	const classes = useStyles();
	const [placeName, setPlaceName] = useState("");
	const [placeType, setPlaceType] = useState("");
	const [placeRate, setPlaceRate] = useState("");

	const placePost = {
		placeName: placeName,
		placeType: placeType,
		placeRate: placeRate,
		geoposition: chosenCoords,
		postId: postContent ? postContent?._id : null,
		createdAt: new Date().toISOString(),
	};

	// Determine if the button should be disabled
	const isButtonDisabled = !placeName || !placeType || !placeRate;

	return (
		<div className={classes.wrapBox}>
			<div className={classes.confirmBox}>
				<Typography variant="h6">Создать локацию</Typography>
				<TextField
					label="Введите название места"
					value={placeName}
					onChange={(e) => setPlaceName(e.target.value.slice(0, 150))} // Limit to 150 characters
					variant="outlined"
					className={classes.input}
					size="small"
					required
				/>
				<Select
					value={placeType}
					onChange={(e) => setPlaceType(e.target.value)}
					displayEmpty
					variant="outlined"
					className={`${classes.select} custom-select`}
					required
				>
					<MenuItem value="">Выберите тип места</MenuItem>
					<MenuItem value={"city"}>Город</MenuItem>
					<MenuItem value={"restaurant"}>Ресторан</MenuItem>
					<MenuItem value={"hotel"}>Отель</MenuItem>
					<MenuItem value={"site"}>Достопримечательность</MenuItem>
					<MenuItem value={"park"}>Парк</MenuItem>
					<MenuItem value={"theatre"}>Театр</MenuItem>
					<MenuItem value={"culture"}>Прочая культура</MenuItem>
					<MenuItem value={"museum"}>Музей</MenuItem>
					<MenuItem value={"church"}>Церковь</MenuItem>
					<MenuItem value={"airport"}>Аэропорт</MenuItem>
					<MenuItem value={"railway"}>Вокзал</MenuItem>
					<MenuItem value={"transport"}>Прочий транспорт</MenuItem>
					<MenuItem value={"other"}>Другое</MenuItem>
				</Select>
				<Select
					value={placeRate}
					onChange={(e) => setPlaceRate(e.target.value)}
					displayEmpty
					variant="outlined"
					className={`${classes.select} custom-select`}
				>
					<MenuItem value="">Поставьте оценку</MenuItem>
					<MenuItem value={1}>1 *</MenuItem>
					<MenuItem value={2}>2 **</MenuItem>
					<MenuItem value={3}>3 ***</MenuItem>
					<MenuItem value={4}>4 ****</MenuItem>
					<MenuItem value={5}>5 *****</MenuItem>
				</Select>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleConfirm(placePost)}
					className={classes.confirmBoxButton}
					disabled={isButtonDisabled} // Disable button based on conditions
				>
					Подтвердить
				</Button>
			</div>
			<img
				className={classes.imagePoint}
				src="https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png"
			/>
		</div>
	);
};

export default ConfirmBox;
