import {
	CREATE_PLACE_REVIEW,
	FETCH_PLACE_REVIEWS,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const createPlaceReview = (newReview) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING });
		const { data } = await api.createPlaceReview(newReview);

		dispatch({ type: CREATE_PLACE_REVIEW, payload: data });
		//dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPlaceReviews = (page, placeId) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING });
		const { data } = await api.getPlaceReviews(page, placeId);
		dispatch({ type: FETCH_PLACE_REVIEWS, payload: data });
		//dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};

export const addYandexPlaceReview = (image) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_AVATAR });
		const { data } = await api.addYandexPlaceReview(image);
		//dispatch({ type: ADD_YANDEX_AVATAR, payload: data });
		//dispatch({ type: END_LOADING_AVATAR });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};
