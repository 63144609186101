import React from "react";
import { Helmet } from "react-helmet";
import { Card, Grid, Button, Typography } from "@material-ui/core";
import useStyles from "./styles";
import aboutUs from "../../images/aboutUs.jpg";
import boosty from "../../images/boosty.png";
import quotation from "../../images/quotation.png";
import ruble from "../../images/ruble.png";

const Support = () => {
	const classes = useStyles();

	return (
		<div>
			<Helmet>
				<title>Поддержать проект - ToTravelRu</title>
				<meta
					name="description"
					content="Поддержать проект с отчетами о путешествиях на Boosty - ToTravelRu"
				/>
				<meta
					name="keywords"
					content="ToTravelRu, путешествия, отчеты, поддержать, Boosty"
				/>
			</Helmet>
			<Typography className={classes.supportTitle}>
				Поддержать проект
			</Typography>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={2}
				className={classes.container}
			>
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					className={classes.card}
					raised
					elevation={6}
				>
					<div className={classes.supportAsk}>
						<img src={aboutUs} alt="" className={classes.supportImg} />
						<Card className={classes.supportCard}>
							<img src={quotation} alt="" className={classes.quotationImg} />
							<Typography className={classes.supportText}>
								Мы создали этот сайт, чтобы вы могли поделиться своим опытом
								путешествий.
							</Typography>
							<Typography className={classes.supportTextName}>
								Елена и Людмила
							</Typography>
						</Card>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<Card className={classes.rangs} raised elevation={6}>
						<Typography className={classes.rangsTitle}>Автор</Typography>
						<Typography className={classes.rangsText}>Преимущества:</Typography>
						<Typography className={classes.rangsText}>
							— Красивый тэг "Автор" рядом с профилем
						</Typography>
						<Typography className={classes.rangsText}>
							— Возможность добавить 20 фотографий в пост
						</Typography>
						<div className={classes.rangsWrap}>
							<Card className={classes.rangsAuthorCard}>
								<Typography className={classes.rangsAuthor}>
									Выдается постоянным авторам
								</Typography>
							</Card>
						</div>
					</Card>
					<Card className={classes.rangs} raised elevation={6}>
						<Typography className={classes.rangsTitle}>Спонсор</Typography>
						<Typography className={classes.rangsText}>Преимущества:</Typography>
						<Typography className={classes.rangsText}>
							— Красивый тэг "Спонсор" рядом с профилем
						</Typography>
						<Typography className={classes.rangsText}>
							— Возможность добавить 20 фотографий в пост
						</Typography>
						<Typography className={classes.rangsText}>
							— Отсутствие рекламы
						</Typography>
						<div className={classes.rangsWrap}>
							<Card className={classes.rangsDevelopment}>
								<img src={ruble} alt="" className={classes.moneyImg} />
								<Typography className={classes.rangsButtonText}>
									99 руб./месяц
								</Typography>
							</Card>
							<Button
								className={classes.rangsButton}
								href="https://boosty.to/crusair/purchase/1753872?ssource=DIRECT&share=subscription_link"
								target="_blank"
							>
								<img src={boosty} alt="" className={classes.boostyImg} />
								<Typography className={classes.rangsButtonText}>
									Купить подписку
								</Typography>
							</Button>
						</div>
					</Card>
					<Card className={classes.rangs} raised elevation={6}>
						<Typography className={classes.rangsTitle}>
							Путешественник
						</Typography>
						<Typography className={classes.rangsText}>Преимущества:</Typography>
						<Typography className={classes.rangsText}>
							— Красивый тэг "Путешественник" рядом с профилем
						</Typography>
						<Typography className={classes.rangsText}>
							— Возможность добавить 20 фотографий в пост
						</Typography>
						<Typography className={classes.rangsText}>
							— Отсутствие рекламы
						</Typography>
						<Typography className={classes.rangsText}>
							— Больше преимуществ в будущем
						</Typography>
						<div className={classes.rangsWrap}>
							<Card className={classes.rangsDevelopment}>
								<img src={ruble} alt="" className={classes.moneyImg} />
								<Typography className={classes.rangsButtonText}>
									299 руб./месяц
								</Typography>
							</Card>
							<Button
								className={classes.rangsButton}
								href="https://boosty.to/crusair/purchase/1753873?ssource=DIRECT&share=subscription_link"
								target="_blank"
							>
								<img src={boosty} alt="" className={classes.boostyImg} />
								<Typography className={classes.rangsButtonText}>
									Купить подписку
								</Typography>
							</Button>
						</div>
					</Card>
					{/*<Card className={classes.rangs} raised elevation={6}>
            <Typography className={classes.rangsTitle}>Гид</Typography>
            <Typography className={classes.rangsText}>
              Полный доступ к функционалу сайта
            </Typography>
            <div className={classes.rangsWrap}>
              <Card className={classes.rangsDevelopment}>
                <Typography className={classes.rangsButtonText}>
                  В разработке
                </Typography>
              </Card>
              <Button
                className={classes.rangsButton}
                href="https://boosty.to/crusair/purchase/1753875?ssource=DIRECT&share=subscription_link"
                target="_blank"
              >
                <img src={boosty} alt="" className={classes.boostyImg} />
                <Typography className={classes.rangsButtonText}>
                  Купить подписку
                </Typography>
              </Button>
            </div>
  </Card>*/}
				</Grid>
			</Grid>
		</div>
	);
};

export default Support;
