import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(1),
		backgroundColor: "#000", // Black background for AboutPhoto
		color: "#fff", // Ensure text is white for contrast
		minHeight: "100vh", // Ensure the component takes the full viewport height
	},
	media: {
		width: "100%",
		height: "auto",
		maxHeight: `calc(100vh - ${theme.spacing(1)}px)`, // Proper calculation for max height
		objectFit: "contain", // Ensure the image scales without being cropped
		borderRadius: theme.shape.borderRadius,
	},
	photoContainer: {
		position: "relative",
	},
	closeButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.9)",
		},
	},
	detailsCard: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "#333", // Set a dark color for the card to contrast with the black background
		color: "#fff", // Ensure the text inside the card is white
	},
	detailsCardContent: {
		padding: theme.spacing(1),
	},
	authorInfo: {
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		width: "50px",
		height: "50px",
		borderRadius: "50%",
	},
	loader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#000", // Fullscreen black background
		color: "#fff", // White color for the spinner
		width: "100vw",
		height: "100vh", // Full viewport height for the loader
	},
	brandContainer: {
		textDecoration: "none",
		color: "#fff",
	},
}));
