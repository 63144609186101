import React from "react";
import { Typography, Box, Link as MuiLink } from "@material-ui/core";
import useStyles from "./styles.js";

// Helper function to generate the Creative Commons abbreviation
const getCreativeCommonsAbbr = (licenseType) => {
	switch (licenseType) {
		case "Attribution":
			return "CC-BY";
		case "Attribution-ShareAlike":
			return "CC-BY-SA";
		case "Attribution-NoDerivs":
			return "CC-BY-ND";
		case "Attribution-NonCommercial-ShareAlike":
			return "CC-BY-NC-SA";
		case "Attribution-NonCommercial-NoDerivs":
			return "CC-BY-NC-ND";
		default:
			return null;
	}
};

// Helper function to generate the URL to the Creative Commons license
const getCreativeCommonsUrl = (licenseType, version) => {
	const abbr = getCreativeCommonsAbbr(licenseType);
	if (abbr && version) {
		return `https://creativecommons.org/licenses/${abbr
			.toLowerCase()
			.replace("cc-", "")}/${version}/`;
	}
	return null;
};

const AuthorInfo = React.memo(({ photoObject, type }) => {
	const classes = useStyles();

	// Generate the Creative Commons abbreviation and URL
	const creativeCommonsAbbr = getCreativeCommonsAbbr(photoObject?.licenseType);
	const creativeCommonsUrl = getCreativeCommonsUrl(
		photoObject?.licenseType,
		photoObject?.creativeCommonsVersion
	);

	return (
		<Box className={classes.imageWrapper}>
			{/* Display author information */}
			{(photoObject?.author || photoObject?.isAuthorCreator) && (
				<Typography
					variant="body1"
					className={
						type === "large" ? classes.authorTextLarge : classes.authorText
					}
				>
					{photoObject?.title &&
						(type === "large" || type === "largeMobile" ? (
							<MuiLink
								href={photoObject?.authorLink}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "black", textDecoration: "underline" }}
							>
								{photoObject?.title}
							</MuiLink>
						) : (
							<MuiLink
								href={photoObject?.authorLink}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "white", textDecoration: "underline" }}
							>
								{photoObject?.title}
							</MuiLink>
						))}
					{photoObject?.title && " "}
					{photoObject?.isAuthorCreator ? (
						photoObject?.name
					) : photoObject?.authorLink ? (
						type === "large" || type === "largeMobile" ? (
							<MuiLink
								href={photoObject?.authorLink}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "black", textDecoration: "underline" }}
							>
								{photoObject?.author}
							</MuiLink>
						) : (
							<MuiLink
								href={photoObject?.authorLink}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "white", textDecoration: "underline" }}
							>
								{photoObject?.author}
							</MuiLink>
						)
					) : (
						photoObject?.author
					)}
					{/*photoObject?.websiteName && ` // ${photoObject?.websiteName}`*/}
					{creativeCommonsAbbr && creativeCommonsUrl && (
						<>
							{" "}
							//{" "}
							{type === "large" || type === "largeMobile" ? (
								<MuiLink
									href={creativeCommonsUrl}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "black", textDecoration: "underline" }}
								>
									{`${creativeCommonsAbbr} ${photoObject?.creativeCommonsVersion}`}
								</MuiLink>
							) : (
								<MuiLink
									href={creativeCommonsUrl}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "white", textDecoration: "underline" }}
								>
									{`${creativeCommonsAbbr} ${photoObject?.creativeCommonsVersion}`}
								</MuiLink>
							)}
						</>
					)}
				</Typography>
			)}
			{photoObject?.isAuthorCreator ? null : (
				<Typography className={classes.changesToPhotoText}>
					Resized and reduced quality
				</Typography>
			)}
		</Box>
	);
});

export default AuthorInfo;
