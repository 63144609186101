import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	link: {
		textDecoration: "none", // Removes underline by default
		"&:hover": {
			textDecoration: "none", // Removes underline on hover
		},
	},
	flexHead: {
		display: "flex",
		alignItems: "center",
	},
	mediaBox: {
		width: "32%",
		marginRight: "3%",
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},
	media: {
		width: "100%", // Ensures the image takes up the full width of the container
		height: 0, // To help with aspect ratio
		paddingTop: "100%", // This is for 16:9 aspect ratio; adjust if necessary
		objectFit: "cover", // Ensures the image is scaled to cover the container
		display: "block",
	},
	titleBox: {
		width: "100%",
	},
	titleBoxSelection: {
		width: "75%",
	},
	title: {
		//width: "50%",
		lineHeight: "1.1",
		textDecoration: "none",
		color: "rgb(0,0,0)",
	},
	titleFull: {
		lineHeight: "1.1",
		textDecoration: "none",
		color: "rgb(0,0,0)",
	},
	textDescription: {
		lineHeight: "1.1",
		fontSize: "0.85rem",
		textDecoration: "none",
		color: "rgb(0,0,0)",
		width: "90%",
	},
	rating: {
		//width: "30%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
	},
	boxType: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "10px",
		marginRight: "5px",
	},
	boxTypeText: {
		color: "rgb(255,255,255)",
		marginRight: "10px",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
	card: {
		margin: "5px 0",
	},
	buttonPrimarySmall: {
		borderRadius: "10px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "5px 0",
		padding: "2px 0",
		width: "100%",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	CardContent: {
		padding: "10px",
	},
	ratingFlex: {
		display: "flex",
		alignItems: "center",
	},
	rateReports: {
		lineHeight: "1.1",
		fontSize: "0.9rem",
		marginRight: "5px",
	},
	numberReports: {
		lineHeight: "1.1",
		color: "rgb(78,78,78)",
		fontSize: "0.85rem",
	},
	statistics: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "rgba(236,98,98,1)",
		borderRadius: "10px",
		width: "fit-content",
		margin: "3px 10px 8px 0",
		color: "rgb(256,256,256)",
		alignItems: "center",
	},
	statisticsBlue: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "rgba(9,9,121,1)",
		borderRadius: "10px",
		width: "fit-content",
		margin: "3px 10px 8px 0",
		color: "rgb(256,256,256)",
		alignItems: "center",
	},
	currencyText: {
		fontSize: "0.85rem",
		marginRight: "5px",
	},
	currencyTextWithMargin: {
		fontSize: "0.85rem",
		margin: "0 5px",
	},
	cityInfoFlex: {
		display: "flex",
		flexWrap: "wrap",
	},
	authorBox: {
		margin: "0 5px 5px 5px",
		padding: "5px 5px 5px 10px",
		background: "none repeat scroll 0 0 rgba(0,0,0,0.75)",
		color: "rgb(256,256,256)",
		borderRadius: "15px",
		width: "fit-content",
		position: "absolute",
		bottom: 0,
		right: 0,
	},
	authorBoxSmall: {
		margin: "5px auto 0 auto",
		padding: "0",
		width: "fit-content",
	},
	visitedBox: {
		color: "rgba(236,98,98,1)",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		bottom: "7px",
		right: "10px",
	},
	visitedIcon: {
		marginRight: "5px",
	},
}));
