import React, { useState } from "react";
import { Typography, Button, Link, Card } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import writing from "../../images/writing.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Pagination, PaginationItem } from "@material-ui/lab";

import useStyles from "./styles";
import {
	addNewPost,
	likePostInReport,
	deletePostReport,
} from "../../actions/posts";
import Resizer from "react-image-file-resizer";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CancelIcon from "@material-ui/icons/Cancel";

const PostReport = (post) => {
	const classes = useStyles();
	const [postsReport, setPostsReport] = useState(post?.post?.postsReport);
	const [textImage, setTextImage] = useState([]);
	const [postReport, setPostReport] = useState({
		reportId: post?.post?._id,
		title: "",
		textImage: textImage,
	});
	const [count, setCount] = useState(0);
	const [deletePostInReport, setDeletePostInReport] = useState({ index: -1 });

	const token = document.cookie
		.split("; ")
		.find((row) => row.startsWith("token="));
	const user = token ? JSON.parse(localStorage.getItem("profile")) : undefined;
	const dispatch = useDispatch();
	const initialPosts = useSelector((state) => {
		return state.posts.initialPosts;
	});
	const numberInitialPosts = initialPosts ? initialPosts.length : null;
	const numberOfPages = Math.ceil(numberInitialPosts / 5);
	const [currentPage, setCurrentPage] = useState(1);
	const initialPostsPagination = initialPosts.slice(
		currentPage * 5 - 5,
		Math.min(currentPage * 5, numberInitialPosts)
	);

	const handlePagination = (event, page) => {
		setCurrentPage(page);
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const addText = () => {
		setCount((prevCount) => {
			return prevCount + 1;
		});
		setTextImage((prevTextImage) => {
			prevTextImage.push(1);
			return prevTextImage;
		});
	};

	const addImage = () => {
		setCount((prevCount) => {
			return prevCount + 1;
		});
		setTextImage((prevTextImage) => {
			prevTextImage.push("Image Upload Asked");
			return prevTextImage;
		});
	};

	const handleClick = async () => {
		const dispatchPosts = await dispatch(
			addNewPost(postReport, post?.post?._id)
		);
		setPostsReport(dispatchPosts);
		setTextImage([]);
		setPostReport({ reportId: post?.post?._id, title: "", textImage: [] });
	};

	const handleLike = async (value, number) => {
		const likeInfo = {
			reportId: post?.post?._id,
			postId: initialPosts[number]._id,
			number: number,
			user: user?.result?._id,
		};
		await dispatch(likePostInReport(likeInfo));
		if (value?.likes.find((like) => like === user?.result?._id)) {
			const postArrayFilter = initialPosts;
			postArrayFilter[number].likes.filter(
				(id) => id !== (user?.result?.googleId || user?.result?._id)
			);
		} else {
			const postArrayPush = initialPosts;
			postArrayPush[number].likes.push(likeInfo.user);
		}
	};

	const handleDeletePost = async () => {
		const postId = initialPosts
			? initialPosts[deletePostInReport.index]?._id
			: null;
		const newPosts = await dispatch(deletePostReport(postId));
		setDeletePostInReport((prev) => {
			return { ...prev, index: -1 };
		});
	};

	return (
		<div className={classes.postsOuterContainer}>
			<div className={classes.commentsInnerContainer}>
				<Typography
					gutterBottom
					variant="h4"
					className={classes.commentsTitlePost}
				>
					Посты
				</Typography>
				{/*<div className={classes.switchContainer}>
            <Typography>Отобразить посты</Typography>
            <Switch
              checked={postType}
              className={classes.switchChangeType}
              onChange={() => setPostType(!postType)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>*/}
			</div>
			{initialPosts.length === 0 ? (
				<div className={classes.noPostsBox}>
					<img className={classes.imageWriting} src={writing} alt="writing" />
					<Typography>Пока нет постов</Typography>
				</div>
			) : null}
			{
				/*postType ? (
          initialPosts.map((c, i) => (
            <div className={classes.postsContainer}>
              <Typography
                gutterBottom
                variant="subtitle1"
                className={classes.titlePost}
              >
                {c.title}
              </Typography>
              {c.textImage.map((o) => (
                <div>
                  {o.image ? (
                    o.image.toString().split(":")[0] == "data" ? (
                      <div className={classes.imageBox}>
                        <CardMedia className={classes.media} image={o.image} />
                        <Typography>{o.description}</Typography>
                      </div>
                    ) : (
                      <Typography gutterBottom variant="subtitle1">
                        {o}
                      </Typography>
                    )
                  ) : (
                    <Typography gutterBottom variant="subtitle1">
                      {o}
                    </Typography>
                  )}
                </div>
              ))}
              <Divider style={{ margin: "20px 0" }} />
            </div>
          ))
        ) : (*/
				<div className={classes.postsLinkOuterContainer}>
					{deletePostInReport?.index !== -1 ? (
						<div>
							<Typography>Вы точно хотите удалить пост?</Typography>
							<div className={classes.yesNo}>
								<Button
									onClick={handleDeletePost}
									className={`${classes.buttonYes} ${classes.writePost} `}
								>
									<Typography className={classes.writePostText}>Да</Typography>
								</Button>
								<Button
									onClick={() =>
										setDeletePostInReport((prev) => {
											return { ...prev, index: -1 };
										})
									}
									className={`${classes.buttonNo} ${classes.writePost} `}
								>
									<Typography className={classes.writePostText}>Нет</Typography>
								</Button>
							</div>
						</div>
					) : (
						initialPostsPagination.map((c, i) => (
							<Card className={classes.postsLinkContainer}>
								<Link className={classes.numberLink} href={`/post/${c._id}`}>
									<div className={classes.numberContainer}>
										<Typography className={classes.numberText}>
											{(currentPage - 1) * 5 + i + 1}
										</Typography>
									</div>

									<Typography
										variant="subtitle1"
										className={classes.titleLinkPost}
									>
										{c.title}
									</Typography>
								</Link>

								<Button
									className={`${classes.flex} ${classes.likesBox}`}
									onClick={() => handleLike(c, (currentPage - 1) * 5 + i)}
								>
									<Typography className={classes.likesNumber}>
										{c.likes.length}
									</Typography>
									{c.likes.find((like) => like === user?.result?._id) ? (
										<FavoriteIcon />
									) : (
										<FavoriteBorderIcon />
									)}
								</Button>
								<div className={classes.visibility}>
									<Typography className={classes.visibilityCount}>
										{c?.visited}
									</Typography>
									<VisibilityIcon />
								</div>
								{user
									? user?.result?._id === post?.post?.creator && (
											<Button
												className={classes.buttonDelete}
												onClick={() =>
													setDeletePostInReport((prev) => {
														return {
															...prev,
															index: (currentPage - 1) * 5 + i,
														};
													})
												}
											>
												<CancelIcon />
											</Button>
									  )
									: null}
							</Card>
						))
					)}
				</div>
			}
			<div className={classes.paginationDiv}>
				<Pagination
					classes={{ ul: classes.ul }}
					count={numberOfPages}
					page={Number(currentPage) || 1}
					variant="outlined"
					color="secondary"
					onChange={handlePagination}
					renderItem={(item) => <PaginationItem {...item} />}
				/>
			</div>
		</div>
	);
};

export default PostReport;
