import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import {
	Avatar,
	Card,
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	Box,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import textFormat from "../../images/text-format.png";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import {
	updateProfile,
	getUserInfoByLogin,
	updateCountries,
	getSubscribersByLogin,
	getFriendsByLogin,
} from "../../actions/users.js";
import EditProfile from "./EditProfile/EditProfile";
import Post from "../Posts/Post/Post";
import PaginationProfile from "./PaginationProfile/PaginationProfile";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { getPostsByUser } from "../../actions/posts";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import MapIcon from "@material-ui/icons/Map";
import { batch } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import ProfilePeople from "./ProfilePeople/ProfilePeople.jsx";
import HandleFriend from "./HandleFriend/HandleFriend.jsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Profile = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const query = useQuery();
	const page = query.get("page") || 1;
	const numberCountries = 233;
	const [isChangeProfile, setIsChangeProfile] = useState(false);
	const [isChangeAbout, setIsChangeAbout] = useState(false);
	const [isChangeCountries, setIsChangeCountries] = useState(false);
	const changeProfile = () => {
		setIsChangeProfile((prevIsChangeProfile) => !prevIsChangeProfile);
	};
	const changeAbout = () => {
		setIsChangeAbout((prevIsChangeAbout) => !prevIsChangeAbout);
	};

	const urlLogin = window.location.pathname.split("/")[2];

	let userCurrent = JSON.parse(localStorage.getItem("profile"));

	useEffect(() => {
		async function fetchData() {
			await batch(() => {
				dispatch(getUserInfoByLogin(urlLogin));
				dispatch(getPostsByUser(urlLogin));
				dispatch(getSubscribersByLogin(urlLogin));
				dispatch(getFriendsByLogin(urlLogin));
			});
		}
		fetchData();
	}, [urlLogin]);

	let user = useSelector((state) => {
		return state.users.result[0];
	});

	let friends = user?.friendsInfo || [];
	let subscribers = user?.subscribersInfo || [];

	const [country, setCountry] = useState(user ? user?.countriesList : "");
	useEffect(() => {
		setCountry(user ? user?.countriesList : []);
	}, [user]);
	const [countryEmoji, setCountryEmoji] = useState("");
	useEffect(() => {
		country?.length > 0
			? setCountryEmoji(country.map((c) => c?.label.split(" ")[0]))
			: console.log("");
	}, [country]);

	const [countryReport, setCountryReport] = useState(
		user ? user?.countriesReportList : ""
	);
	useEffect(() => {
		setCountryReport(user ? user?.countriesReportList : []);
	}, [user]);
	const [countryReportEmoji, setCountryReportEmoji] = useState("");
	useEffect(() => {
		countryReport?.length > 0
			? setCountryReportEmoji(countryReport.map((c) => c.split(" ")[0]))
			: console.log("");
	}, [countryReport]);

	const options = useMemo(() => countryList().getData(), []);

	const changeHandler = (country) => {
		setCountry(country);
	};

	const submitCountryList = async () => {
		const login = user?.login;
		await dispatch(updateCountries(country, login));
		setIsChangeCountries(!isChangeCountries);
	};

	const checkUser = user?.login
		? userCurrent?.result?.login
			? user?.login === userCurrent?.result?.login
			: false
		: false;

	const isLoadingUser = useSelector((state) => {
		return state.users.isLoading;
	});
	const userPosts = useSelector((state) => state.posts.post?.data);
	const editInitialState = JSON.parse(localStorage.getItem("profile"));
	const [editFormData, setEditFormData] = useState({ result: user });
	useEffect(() => {
		setEditFormData(user ? { result: user } : null);
	}, [user]);

	const handleEditChange = (e) => {
		setEditFormData({
			...editFormData,
			result: { ...editFormData.result, [e.target.name]: e.target.value },
		});
	};

	const handleSubmitForb = (e) => {
		e.preventDefault();
		if (editFormData === editInitialState) {
			console.log("Вы ничего не изменили");
		} else {
			const checking = dispatch(updateProfile(editFormData));
		}
	};

	return (
		<>
			<Helmet>
				<title>{`Пользователь ${user ? user?.login : ""}`} - ToTravelRu</title>
				<meta
					name="description"
					content={`Страница пользователя ${user ? user?.name : ""}, @${
						user ? user?.login : ""
					} - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${user ? user?.name : ""}, ${
						user ? user?.login : ""
					} , отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu`}
				/>
			</Helmet>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={3}
				className={classes.gridContainer}
			>
				<Grid item xs={12} sm={6} md={3}>
					<Card className={classes.card}>
						{isLoadingUser ? (
							<div className={classes.circularProfile}>
								<CircularProgress color="secondary" />
							</div>
						) : (
							<>
								<div className={classes.photoName}>
									<Avatar
										className={classes.profilePicture}
										alt={user?.name}
										src={user?.picture}
									>
										{user?.name.charAt(0)}
									</Avatar>
									<Typography variant="h5">{user?.name}</Typography>
									{user?.userType === "admin" ? (
										<Typography className={classes.adminSponsorLabel}>
											Администратор
										</Typography>
									) : user?.userType === "sponsor" ? (
										<Typography className={classes.adminSponsorLabel}>
											Спонсор
										</Typography>
									) : user?.userType === "traveler" ? (
										<Typography className={classes.adminSponsorLabel}>
											Путешественник
										</Typography>
									) : user?.userType === "author" ? (
										<Typography className={classes.adminSponsorLabel}>
											Автор
										</Typography>
									) : null}
									{isChangeProfile ? null : checkUser ? (
										<Button
											variant="contained"
											className={classes.buttonPrimary}
											onClick={changeProfile}
										>
											Изменить профиль
										</Button>
									) : null}
								</div>
								<hr className={classes.divider} />
								<div className={classes.infoProfile}>
									<div className={classes.descWrap}>
										<MenuBookIcon className={classes.logo} />
										<Typography className={classes.descText}>Отчеты</Typography>
										<Box className={classes.userStatWrap}>
											<Typography>
												{user ? user?.reportsId.length : null}
											</Typography>
										</Box>
									</div>
									<div className={classes.descWrap}>
										<KeyboardIcon className={classes.logo} />
										<Typography className={classes.descText}>Посты</Typography>
										<Box className={classes.userStatWrap}>
											<Typography>{user?.postsNumber}</Typography>
										</Box>
									</div>
									<div className={classes.descWrap}>
										<ThumbUpAltOutlined
											fontSize="small"
											className={classes.logo}
										/>
										<Typography className={classes.descText}>Лайки</Typography>
										<Box className={classes.userStatWrap}>
											<Typography>{user?.likesNumber}</Typography>
										</Box>
									</div>
								</div>
							</>
						)}
					</Card>
					<div className={classes.spaceDivider}></div>
					<Card className={classes.card}>
						<ProfilePeople list={friends} user={user} />
					</Card>
					<div className={classes.spaceDivider}></div>
					<Card className={classes.card}>
						<ProfilePeople list={subscribers} type="subscribers" user={user} />
					</Card>

					<div className={classes.spaceDivider}></div>
					{checkUser || !userCurrent ? null : !user?.friends?.includes(
							userCurrent?.result?._id
					  ) && !user?.subscribers?.includes(userCurrent?.result?._id) ? (
						<HandleFriend userCurrent={userCurrent} user={user} type="add" />
					) : null}
					<div className={classes.spaceDivider}></div>
					{checkUser || !userCurrent ? null : user?.subscribers?.includes(
							userCurrent?.result?._id
					  ) ? (
						<HandleFriend
							userCurrent={userCurrent}
							user={user}
							type="unsubscribe"
						/>
					) : null}
					{checkUser || !userCurrent ? null : user?.friends?.includes(
							userCurrent?.result?._id
					  ) ? (
						<HandleFriend
							userCurrent={userCurrent}
							user={user}
							type="removeFriend"
						/>
					) : null}
					{/*
        <Card className={classes.cardAdvert}>
          <Typography>Реклама</Typography>
        </Card>*/}
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					{isChangeProfile ? (
						<EditProfile
							isChangeProfile={isChangeProfile}
							setIsChangeProfile={setIsChangeProfile}
						/>
					) : (
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={3}
							className={classes.gridContainer}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								className={classes.gridContainerBox}
							>
								<Card className={classes.cardAbout}>
									<Typography variant="h5">О себе</Typography>
									{isLoadingUser ? (
										<div className={classes.circularOther}>
											<CircularProgress color="secondary" />
										</div>
									) : (
										<>
											{isChangeAbout ? (
												<form
													className={classes.form}
													onSubmit={handleSubmitForb}
												>
													<TextField
														name="about"
														variant="outlined"
														label="О себе"
														fullWidth
														multiline
														maxRows={6}
														value={editFormData?.result?.about}
														onChange={handleEditChange}
													/>
													<Button
														className={classes.buttonPrimary}
														variant="contained"
														size="large"
														type="submit"
														fullWidth
													>
														Написать
													</Button>
												</form>
											) : user?.about ? (
												<>
													<Typography>{user.about}</Typography>
													{checkUser ? (
														<div className={classes.buttonBoxAbout}>
															<Button
																className={classes.buttonPrimary}
																onClick={() => setIsChangeAbout(!isChangeAbout)}
															>
																Изменить описание
															</Button>
														</div>
													) : null}
												</>
											) : (
												<>
													<img
														src={textFormat}
														className={classes.imageAbout}
														alt="totravel"
														height="40px"
														width="40px"
													/>
													{checkUser ? (
														<>
															<Typography>
																Можете написать о своих путешествиях, количестве
																стран, которые вы посетили
															</Typography>
															<Button
																className={classes.buttonPrimary}
																onClick={changeAbout}
															>
																Расскажите о себе
															</Button>
														</>
													) : (
														<Typography>
															Пользователь не указал информацию о себе
														</Typography>
													)}
												</>
											)}
										</>
									)}
								</Card>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								className={classes.gridContainerBox}
							>
								<Card className={classes.cardStatistics}>
									<Typography variant="h5">Статистика</Typography>
									{isLoadingUser ? (
										<div className={classes.circularOther}>
											<CircularProgress color="secondary" />
										</div>
									) : (
										<>
											{country.length > 0 ? (
												<div className={classes.statisticsFlex}>
													<div className={classes.flexVisited}>
														<Typography
															className={classes.visitedCountriesText}
														>
															Пользователь посетил
														</Typography>
														<div className={classes.flex}>
															<Box className={classes.countriesNumberBox}>
																<Typography
																	className={classes.countriesNumberText}
																>{`${country.length}`}</Typography>
															</Box>
															<Typography>
																{country?.length === 1
																	? `страну из 233`
																	: country?.length > 4
																	? `стран из 233`
																	: `страны из 233`}
															</Typography>
														</div>
													</div>
													<LinearProgress
														variant="determinate"
														value={(country.length / numberCountries) * 100}
														className={classes.linearProgress}
														color="secondary"
													/>
												</div>
											) : null}
											{countryEmoji.length > 0 ? null : (
												<MapIcon className={classes.mapIconNoCountries} />
											)}
											<Typography
												variant="subtitle1"
												className={classes.emojiList}
											>
												{countryEmoji.length > 0
													? countryEmoji.join("")
													: "Пользователь не указал посещенные страны"}
											</Typography>
											{countryReport.length > 0 ? (
												<div className={classes.statisticsFlex}>
													<div className={classes.flexVisited}>
														<Typography
															className={classes.visitedCountriesText}
														>
															Написано отчетов о
														</Typography>
														<div className={classes.flex}>
															<Box className={classes.countriesNumberBox}>
																<Typography
																	className={classes.countriesNumberText}
																>{`${countryReport.length}`}</Typography>
															</Box>
															<Typography>
																{countryReport.length === 1
																	? `стране`
																	: `странах`}
															</Typography>
														</div>
													</div>
													<LinearProgress
														variant="determinate"
														value={
															(countryReport.length / numberCountries) * 100
														}
														className={classes.linearProgress}
														color="secondary"
													/>
												</div>
											) : null}
											<Typography
												variant="subtitle1"
												className={classes.emojiList}
											>
												{countryReportEmoji.length > 0
													? countryReportEmoji.join("")
													: "Пользователь пока не написал отчеты"}
											</Typography>
											{isChangeCountries ? (
												<div>
													<Select
														options={options}
														value={country}
														onChange={changeHandler}
														styles={{
															menu: (styles) => ({
																...styles,
																position: "static",
																zIndex: 1000,
															}),
															control: (styles) => ({
																...styles,
																fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
															}),
															option: (styles) => {
																return {
																	...styles,
																	fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
																};
															},
														}}
														isMulti
														placeholder="Выберите страну"
													/>
													<Button
														onClick={submitCountryList}
														className={classes.buttonPrimary}
													>
														Обновить список
													</Button>
												</div>
											) : checkUser ? (
												<div>
													<Button
														onClick={() => {
															setIsChangeCountries(!isChangeCountries);
														}}
														className={classes.buttonPrimary}
													>
														<MapIcon className={classes.mapIcon} />
														<Typography className={classes.addCountriesText}>
															Добавить страны
														</Typography>
													</Button>
												</div>
											) : null}
										</>
									)}
								</Card>
							</Grid>
						</Grid>
					)}
					<Card className={classes.cardPost}>
						<Typography variant="h5" className={classes.reportsTitle}>
							Отчеты пользователя
						</Typography>
						<div className={classes.reportsBox}>
							{userPosts === undefined ? (
								<div className={classes.circularProfile}>
									<CircularProgress color="secondary" />
								</div>
							) : userPosts.length === 0 ? (
								<div>
									<Typography>Отчетов пока нет</Typography>
									<Typography>
										Вы можете написать отчет, кликнув на кнопку ниже
									</Typography>
									<Button className={classes.buttonPrimary} href="/posts">
										<Typography>Написать отчет</Typography>
									</Button>
								</div>
							) : (
								<div>
									<Grid
										container
										justifyContent="space-evenly"
										alignItems="stretch"
										spacing={3}
										className={classes.gridContainerPosts}
									>
										{userPosts.map((post) => (
											<Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
												<Post post={post} setCurrentId={0}></Post>
											</Grid>
										))}
									</Grid>
								</div>
							)}
						</div>
					</Card>
					{userPosts === undefined ? (
						<Paper className={classes.pagination}>
							<CircularProgress color="secondary" />
						</Paper>
					) : user?.reportsId.length ? (
						<Paper className={classes.pagination}>
							<PaginationProfile page={page} urlLogin={urlLogin} />
						</Paper>
					) : null}
				</Grid>
			</Grid>
		</>
	);
};

export default Profile;
