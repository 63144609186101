import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	Typography,
	Button,
	CardMedia,
	Grid,
	Card,
	Select,
	MenuItem,
	FormControl,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputLabel,
} from "@material-ui/core";
import useStyles from "./styles.js";
import Resizer from "react-image-file-resizer";
import PublishIcon from "@material-ui/icons/Publish";
import { addPhotosToPlace, addYandexPlace } from "../../../../actions/place.js";

const PlaceAddPhoto = ({ place, user, setIsAddImage }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [editFormData, setEditFormData] = useState();
	const [uploadedImages, setUploadedImages] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [imageTypes, setImageTypes] = useState({}); // Track selected types for each image

	useEffect(() => {
		setEditFormData(place ? { placeId: place._id } : null);
	}, [place]);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const handleFileChange = async (event) => {
		try {
			const files = Array.from(event.target.files);
			if (files.length + uploadedImages.length > 5) {
				alert("Вы можете загрузить до 5 фотографий.");
				return;
			}

			const newImages = await Promise.all(
				files.map(async (file) => {
					if (file.size > 10000000) {
						alert("Выберите файл поменьше.");
						return null;
					} else {
						const resizedImage = await resizeFile(file);
						const uploadedImage = await dispatch(addYandexPlace(resizedImage));
						return uploadedImage;
					}
				})
			);

			const validNewImages = newImages.filter((image) => image !== null);
			const imageObjects = validNewImages.map((image) => ({
				userId: user?._id,
				image: image,
				likes: 0,
				type: "",
				title: "",
				isAuthorCreator: true, // Default value for each image
				photoShootAt: null,
				licenseType: "",
				creativeCommonsVersion: "",
				author: "",
				authorLink: "",
				websiteName: "",
			}));

			setUploadedImages([...uploadedImages, ...imageObjects]);
			setFileNames([...fileNames, ...files.map((file) => file.name)]);

			setEditFormData((prevData) => ({
				...prevData,
				images: [...(prevData?.images || []), ...imageObjects],
			}));
		} catch (err) {
			console.log(err);
		}
	};

	// Handle updates to each property for a specific image
	const handleImagePropertyChange = (index, property, value) => {
		const updatedImages = [...uploadedImages];
		updatedImages[index][property] = value;
		setUploadedImages(updatedImages);
	};

	const getTypeOptions = () => {
		if (place?.placeType === "city") {
			return [
				{ value: "histQuarter", label: "Исторический квартал" },
				{ value: "businessQuarter", label: "Деловой квартал" },
				{ value: "sea", label: "Море" },
				{ value: "other", label: "Прочее" },
			];
		} else if (place?.placeType === "restaurant") {
			return [
				{ value: "menu", label: "Меню" },
				{ value: "meals", label: "Блюда" },
				{ value: "interior", label: "Интерьер" },
				{ value: "other", label: "Прочее" },
			];
		} else if (place?.placeType === "hotel") {
			return [
				{ value: "rooms", label: "Номера" },
				{ value: "pool", label: "Бассейн" },
				{ value: "restaurant", label: "Ресторан" },
				{ value: "building", label: "Здание" },
				{ value: "other", label: "Прочее" },
			];
		}
		return [];
	};

	const getLicenseOptions = () => [
		"All Rights Reserved",
		"Public Domain Work",
		"Attribution",
		"Attribution-ShareAlike",
		"Attribution-NoDerivs",
		"Attribution-NonCommercial-ShareAlike",
		"Attribution-NonCommercial-NoDerivs",
	];

	const getCreativeCommonsVersions = () => [
		"1.0",
		"2.0",
		"2.1",
		"2.5",
		"3.0",
		"4.0",
	];

	const handleSubmitForb = (e) => {
		e.preventDefault();
		dispatch(addPhotosToPlace(editFormData));
		setIsAddImage((prevIs) => !prevIs);
	};

	return (
		<form className={classes.form} onSubmit={handleSubmitForb}>
			<div className={classes.fileInput}>
				<label className={classes.labelInput}>
					<input
						type="file"
						className={classes.imageInput}
						multiple
						onChange={handleFileChange}
					/>
					<PublishIcon />
					<Typography className={classes.uploadText}>ДОБАВИТЬ ФОТО</Typography>
				</label>
			</div>

			{/* Display uploaded images and allow selecting type and other properties for each */}
			<Grid container spacing={1} className={classes.imagePreviewContainer}>
				{uploadedImages.map((image, index) => (
					<Grid item key={index}>
						<Card>
							<CardMedia
								component="img"
								className={classes.imagePreview}
								alt={`uploaded ${index}`}
								image={image.image}
								title={`uploaded ${index}`}
							/>

							{/* Author Selection */}
							<FormControl component="fieldset">
								<Typography>Кто автор фото?</Typography>
								<RadioGroup
									value={image.isAuthorCreator ? "true" : "false"}
									onChange={(e) =>
										handleImagePropertyChange(
											index,
											"isAuthorCreator",
											e.target.value === "true"
										)
									}
								>
									<FormControlLabel
										value="true"
										control={<Radio />}
										label="Вы"
									/>
									<FormControlLabel
										value="false"
										control={<Radio />}
										label="Другой человек"
									/>
								</RadioGroup>
							</FormControl>

							{/* Conditional Inputs Based on Author Selection */}
							{image.isAuthorCreator ? (
								<>
									{/* Photo Date */}
									<TextField
										label="Заголовок фото"
										value={image.title}
										onChange={(e) =>
											handleImagePropertyChange(index, "title", e.target.value)
										}
										inputProps={{ maxLength: 200 }}
										fullWidth
										required
									/>

									<FormControl fullWidth>
										<TextField
											type="date"
											label="Дата съемки"
											value={
												image.photoShootAt
													? image.photoShootAt.split("T")[0]
													: ""
											}
											onChange={(e) =>
												handleImagePropertyChange(
													index,
													"photoShootAt",
													e.target.value
												)
											}
											className={classes.dateInput} // Optional, if you want to add styling
										/>
									</FormControl>

									{/* License Selection */}
									<FormControl fullWidth required>
										<InputLabel>Лицензия</InputLabel>
										<Select
											value={image.licenseType}
											onChange={(e) =>
												handleImagePropertyChange(
													index,
													"licenseType",
													e.target.value
												)
											}
										>
											{getLicenseOptions().map((option) => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{/* Creative Commons Version (Shown for specific licenses) */}
									{image.licenseType !== "All Rights Reserved" &&
										image.licenseType !== "Public Domain Work" && (
											<FormControl fullWidth required>
												<InputLabel>Creative Commons Version</InputLabel>
												<Select
													value={image.creativeCommonsVersion}
													onChange={(e) =>
														handleImagePropertyChange(
															index,
															"creativeCommonsVersion",
															e.target.value
														)
													}
												>
													{getCreativeCommonsVersions().map((version) => (
														<MenuItem key={version} value={version}>
															{version}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
								</>
							) : (
								<>
									{/* Author Information */}
									<TextField
										label="Заголовок фото"
										value={image.title}
										onChange={(e) =>
											handleImagePropertyChange(index, "title", e.target.value)
										}
										inputProps={{ maxLength: 200 }}
										fullWidth
										required
									/>
									<TextField
										label="Имя автора"
										value={image.author}
										onChange={(e) =>
											handleImagePropertyChange(index, "author", e.target.value)
										}
										inputProps={{ maxLength: 100 }}
										fullWidth
										required
									/>
									<TextField
										label="Ссылка на автора"
										value={image.authorLink}
										onChange={(e) =>
											handleImagePropertyChange(
												index,
												"authorLink",
												e.target.value
											)
										}
										inputProps={{ maxLength: 500 }}
										fullWidth
										required
									/>
									<TextField
										label="Название сайта"
										value={image.websiteName}
										onChange={(e) =>
											handleImagePropertyChange(
												index,
												"websiteName",
												e.target.value
											)
										}
										inputProps={{ maxLength: 50 }}
										fullWidth
										required
									/>

									{/* License Selection */}
									<FormControl fullWidth required>
										<InputLabel>Лицензия</InputLabel>
										<Select
											value={image.licenseType}
											onChange={(e) =>
												handleImagePropertyChange(
													index,
													"licenseType",
													e.target.value
												)
											}
										>
											{getLicenseOptions()
												.filter((option) => option !== "All Rights Reserved")
												.map((option) => (
													<MenuItem key={option} value={option}>
														{option}
													</MenuItem>
												))}
										</Select>
									</FormControl>

									{/* Creative Commons Version */}
									{image.licenseType !== "Public Domain Work" && (
										<FormControl fullWidth required>
											<InputLabel>Creative Commons Version</InputLabel>
											<Select
												value={image.creativeCommonsVersion}
												onChange={(e) =>
													handleImagePropertyChange(
														index,
														"creativeCommonsVersion",
														e.target.value
													)
												}
											>
												{getCreativeCommonsVersions().map((version) => (
													<MenuItem key={version} value={version}>
														{version}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</>
							)}

							{/* Type Selection */}
							<FormControl fullWidth>
								<Select
									value={image.type || ""}
									onChange={(e) =>
										handleImagePropertyChange(index, "type", e.target.value)
									}
									displayEmpty
								>
									<MenuItem value="" disabled>
										Выберите тип
									</MenuItem>
									{getTypeOptions().map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Card>
					</Grid>
				))}
			</Grid>

			<Button
				className={classes.buttonPrimary}
				type="submit"
				variant="contained"
				fullWidth
			>
				Разместить фото
			</Button>
		</form>
	);
};

export default PlaceAddPhoto;
