import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { getLastComments } from "../../../actions/admin";
import { Link } from "react-router-dom"; // Import Link

const LastComments = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const lastComments = useSelector((state) => state?.admin?.lastComments); // Accessing lastPhotos from Redux state

	const handleLoadLastComments = () => {
		dispatch(getLastComments());
	};

	return (
		<div>
			<Typography variant="h5">Последние комментарии</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={handleLoadLastComments}
			>
				Загрузить последние комментарии
			</Button>
			<Grid container spacing={2} className={classes.gridContainer}>
				{lastComments && lastComments.length > 0 ? (
					lastComments.map((photo) => (
						<Grid item xs={12} sm={6} md={6} key={photo._id}>
							<div className={classes.photoContainer}>
								<Typography variant="subtitle1">{photo?.name}</Typography>
								<Link
									to={`/user/${photo?.login}`}
									className={classes.profileLink}
								>
									<Typography variant="subtitle1">{photo?.login}</Typography>
								</Link>
								{photo?.postId && (
									<Link
										to={`/post/${photo?.postId}`}
										className={classes.profileLink}
									>
										<Typography variant="subtitle1">Перейти в пост</Typography>
									</Link>
								)}
								{photo?.reportId && (
									<Link
										to={`/posts/${photo?.reportId}`}
										className={classes.profileLink}
									>
										<Typography variant="subtitle1">Перейти в отчет</Typography>
									</Link>
								)}
								<Typography>{photo?.comment}</Typography>
							</div>
						</Grid>
					))
				) : (
					<Typography>Комментарии отсутствуют</Typography>
				)}
			</Grid>
		</div>
	);
};

export default LastComments;
