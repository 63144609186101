import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		position: "relative",
	},
	mapContainer: {
		height: "300px",
	},
	sliderContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
	},
	imageWrapper: {
		width: "100%",
		maxWidth: "500px",
		textAlign: "center",
		position: "relative",
		display: "inline-block",
	},
	menuButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		color: "white",
		backgroundColor: "rgba(0, 0, 0, 0.2)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
		},
		zIndex: 1,
	},
	image: {
		maxWidth: "100%",
		height: "auto",
		maxHeight: "400px",
		cursor: "pointer",
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
	},
	postItem: {
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
		border: `solid 1px rgba(236, 98, 98, 1)`, // Border style
		borderRadius: "15px", // Border radius
	},
	gridItem: {
		borderRadius: "15px", // Border radius for grid items
		borderColor: "rgba(236, 98, 98, 1)", // Border color for grid items
		border: "solid 1px", // Border style for grid items
		padding: theme.spacing(2), // Add padding to grid items
		height: "calc(100% - 24px)",
	},
	postsContainer: {
		borderRadius: "15px", // Border radius for the container of "Посты"
		borderColor: "rgba(236, 98, 98, 1)", // Border color for the container of "Посты"
		border: "solid 1px", // Border style for the container of "Посты"
		marginTop: "20px",
		padding: theme.spacing(2),
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		paddingLeft: "30px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonPrimarySuggestText: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		paddingLeft: "30px",
		marginRight: "5px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	ratingBox: {
		display: "flex",
		alignItems: "center",
		//paddingBottom: "10px",
	},
	buttonSecondary: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(70,70,70,1)",
		color: "rgba(255,255,255,1)",
		marginLeft: "5px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(100,100,100,1)",
		},
	},
	buttonSecondarySuggestText: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(70,70,70,1)",
		color: "rgba(255,255,255,1)",
		marginLeft: "5px",
		marginRight: "5px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(100,100,100,1)",
		},
	},
	mapButtonFlex: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		//flexWrap: "wrap",
	},
	boxType: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: "10px",
		marginRight: "5px",
	},
	boxTypeText: {
		color: "rgb(255,255,255)",
		marginRight: "10px",
		textTransform: "none",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
	placeTypeBoxes: {
		display: "flex",
		flexWrap: "wrap",
	},
	buttonCity: {
		padding: "0",
	},
	rating: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
	},
	ratingFlex: {
		display: "flex",
		alignItems: "center",
	},
	rateReports: {
		lineHeight: "1.1",
		fontSize: "0.9rem",
		marginRight: "5px",
	},
	numberReports: {
		lineHeight: "1.1",
		color: "rgb(230,230,230)",
		fontSize: "0.85rem",
	},
	descriptionTextBox: {
		padding: "5px",
		backgroundColor: "rgb(237, 237, 237)",
		borderRadius: "10px",
		margin: "3px 0",
	},
	mainImage: {
		width: "100%",
		height: "250px",
		objectFit: "cover",
		objectPosition: "center",
		maxHeight: "250px",
		borderRadius: "15px",
	},
	mainImageContainer: {
		position: "relative",
	},
	menuTitleBox: { position: "absolute", bottom: "0", left: "0" },
	cityTitleBox: {
		margin: "5px",
		padding: "5px 10px",
		background: "none repeat scroll 0 0 rgba(0,0,0,0.75)",
		color: "rgb(256,256,256)",
		borderRadius: "15px",
		width: "fit-content",
	},
	buttonRow: {
		display: "flex",
		justifyContent: "space-around",
		backgroundColor: "rgba(256, 256, 256, 1)",
		padding: "5px 0",
		zIndex: 1000,
		borderRadius: "15px",
		marginBottom: "5px",
	},
	sticky: {
		position: "sticky",
		top: 0,
		zIndex: 1000,
	},
	buttonPrimaryMenu: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "4px 20px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	noImageScenario: {
		position: "relative",
		background:
			"linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(236,98,98,1) 100%)",
		height: "254px", // Adjust as needed
		width: "100%", // Adjust as needed
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "15px",
	},
	cityTitleBoxFinal: {
		margin: "5px 5px 10px 5px",
		padding: "5px 5px 5px 10px",
		background: "none repeat scroll 0 0 rgba(0,0,0,0.75)",
		color: "rgb(256,256,256)",
		borderRadius: "15px",
		width: "fit-content",
	},
	postsContainerNearest: {
		borderRadius: "15px", // Border radius for the container of "Посты"
		borderColor: "rgba(236, 98, 98, 1)", // Border color for the container of "Посты"
		border: "solid 1px", // Border style for the container of "Посты"
		marginTop: "20px",
		padding: theme.spacing(2),
	},
	photosByTypeBox: {
		display: "flex",
		flexDirection: "column",
	},
	boxType: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: "10px",
		margin: "5px 5px 5px 0",
	},
	boxTypeText: {
		color: "rgb(255,255,255)",
		marginRight: "10px",
		textTransform: "none",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
}));
