import React from "react";
import { Typography, Button, Box, Hidden } from "@material-ui/core";
import {
	translateDayToRussian,
	translatePlaceTypePrepositionalCase,
} from "../../../../utils/placeUtils.js";
import useStyles from "./styles.js";
import TemperatureChart from "./TemperatureChart/TemperatureChart.jsx";
import PrecipitationChart from "./PrecipitationChart/PrecipitationChart.jsx";

const PlaceInformation = ({
	place,
	averageHotelRate,
	averageCheck,
	averageTicketAdult,
	averageTicketChild,
}) => {
	const classes = useStyles();

	const formatWorkingHours = (day) => {
		const hours = place?.workingHours?.[day];
		if (hours && hours[0] === "dayoff" && hours[1] === "dayoff") {
			return "Выходной";
		}
		return hours ? `${hours[0]} - ${hours[1]}` : "Не указано";
	};

	const hasNonEmptyHours = (workingHours) => {
		return Object.values(workingHours).some((hours) => hours.length > 0);
	};

	const renderCityInformation = () => {
		if (place?.placeType === "city") {
			const {
				population,
				firstMentioned,
				timeZone,
				timeZoneSummer,
				averageTempDay,
				averageTempNight,
				averageTempWater,
				averagePrecipitation,
			} = place;

			if (population || firstMentioned || timeZone) {
				return (
					<Box>
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/info.png"
								alt="info"
							/>
							<Typography className={classes.descriptionTitle}>
								Особенности города
							</Typography>
						</div>
						{population && (
							<div className={classes.flexWrap}>
								<Typography className={classes.descriptionTextBefore}>
									Численность населения:{" "}
								</Typography>
								<Typography>
									{population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
								</Typography>
							</div>
						)}
						{firstMentioned && (
							<div className={classes.flexWrap}>
								<Typography className={classes.descriptionTextBefore}>
									Год основания:
								</Typography>
								<Typography>
									{firstMentioned.startsWith("-")
										? `${Math.abs(firstMentioned.slice(0, 4))} до н. э.`
										: firstMentioned.slice(0, 4)}
								</Typography>
							</div>
						)}
						{timeZone && (
							<div className={classes.flexWrap}>
								<Typography className={classes.descriptionTextBefore}>
									Часовой пояс:
								</Typography>
								<Typography>{timeZone}</Typography>
							</div>
						)}
						{timeZoneSummer && (
							<div className={classes.flexWrap}>
								<Typography className={classes.descriptionTextBefore}>
									Часовой пояс летом:
								</Typography>
								<Typography>{timeZoneSummer}</Typography>
							</div>
						)}
						{averageTempDay && averageTempNight && (
							<TemperatureChart
								averageTempDay={averageTempDay}
								averageTempNight={averageTempNight}
							/>
						)}
						{averagePrecipitation && (
							<PrecipitationChart averagePrecipitation={averagePrecipitation} />
						)}
					</Box>
				);
			}
		}
		return null;
	};

	const renderHotelInformation = () => {
		if (place?.placeType === "hotel") {
			const {
				checkInTime,
				checkOutTime,
				numberOfFloors,
				numberOfRooms,
				yearBuilt,
				yearRenovated,
				isAllowedPets,
				breakfastCost,
				breakfastTimeStart,
				breakfastTimeEnd,
			} = place;

			return (
				<Box>
					<div className={classes.flex}>
						<img
							width="24"
							height="24"
							src="https://img.icons8.com/sf-regular/ec6262/96/info.png"
							alt="info"
						/>
						<Typography className={classes.descriptionTitle}>
							Особенности
						</Typography>
					</div>

					{checkInTime && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Время заезда:
							</Typography>
							<Typography>{checkInTime}</Typography>
						</div>
					)}

					{checkOutTime && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Время выезда:
							</Typography>
							<Typography>{checkOutTime}</Typography>
						</div>
					)}

					{numberOfFloors && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Количество этажей:
							</Typography>
							<Typography>{numberOfFloors}</Typography>
						</div>
					)}

					{numberOfRooms && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Количество номеров:
							</Typography>
							<Typography>{numberOfRooms}</Typography>
						</div>
					)}

					{yearBuilt && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Год постройки:
							</Typography>
							<Typography>{yearBuilt}</Typography>
						</div>
					)}

					{yearRenovated && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Год реконструкции:
							</Typography>
							<Typography>{yearRenovated}</Typography>
						</div>
					)}

					{isAllowedPets && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Разрешено проживание с питомцами:
							</Typography>
							<Typography>
								{isAllowedPets === true || isAllowedPets === "true"
									? "Да"
									: "Нет"}
							</Typography>
						</div>
					)}

					{breakfastCost && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Стоимость завтрака:
							</Typography>
							<Typography>
								{breakfastCost.price} {breakfastCost.currency}
							</Typography>
						</div>
					)}

					{breakfastTimeStart && breakfastTimeEnd && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Время завтрака:
							</Typography>
							<Typography>
								{breakfastTimeStart} - {breakfastTimeEnd}
							</Typography>
						</div>
					)}
				</Box>
			);
		}
		return null;
	};

	return (
		<div>
			{/* Address */}
			{place?.address && (
				<div>
					<div className={classes.flex}>
						<img
							width="24"
							height="24"
							src="https://img.icons8.com/sf-regular/ec6262/96/address.png"
							alt="address"
						/>
						<Typography className={classes.descriptionTitle}>Адрес</Typography>
					</div>
					<Typography>{place.address}</Typography>
				</div>
			)}

			{/* Contact details */}
			{place?.phone || place?.website ? (
				<div>
					<div className={classes.flex}>
						<img
							width="24"
							height="24"
							src="https://img.icons8.com/sf-regular/ec6262/96/phone.png"
							alt="phone"
						/>
						<Typography className={classes.descriptionTitle}>
							Контакты
						</Typography>
					</div>
				</div>
			) : null}

			<div className={classes.contactsBox}>
				{place?.phone && (
					<div className={classes.flexWrap}>
						<Typography className={classes.descriptionTextBefore}>
							Телефон:
						</Typography>
						<Typography>{place.phone}</Typography>
					</div>
				)}
				{place?.phone && place?.website ? (
					<Hidden xsDown>
						<Typography className={classes.contactsDivider}>|</Typography>
					</Hidden>
				) : null}
				{place?.website && (
					<div className={classes.flexWrap}>
						<Typography className={classes.descriptionTextBefore}>
							Вебсайт:
						</Typography>
						<Typography>
							<a
								href={
									place.website.startsWith("http://") ||
									place.website.startsWith("https://")
										? place.website
										: `http://${place.website}`
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								{place.website}
							</a>
						</Typography>
					</div>
				)}
			</div>

			{/* Working hours */}
			{place?.workingHours &&
				place?.workingHours.monday.length !== 0 &&
				place?.workingHours?.monday[0] !== "" &&
				hasNonEmptyHours(place?.workingHours) && (
					<Box>
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/clock.png"
								alt="phone"
							/>
							<Typography className={classes.descriptionTitle}>
								Часы работы:
							</Typography>
						</div>
						{Object.keys(place.workingHours).map((day) => (
							<div className={classes.workingHoursBox}>
								<Typography key={day}>
									{translateDayToRussian(day).charAt(0).toUpperCase() +
										translateDayToRussian(day).slice(1)}
								</Typography>
								<Typography key={day}>{formatWorkingHours(day)}</Typography>
							</div>
						))}
					</Box>
				)}

			{/* Render City Information */}
			{renderCityInformation()}

			{/* Hotel Information */}
			{place?.placeType === "hotel" && renderHotelInformation()}

			{/* Hotel Rate */}
			{place?.placeType === "hotel" && averageHotelRate > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена за ночь: {Math.round(averageHotelRate)} ₽
					</Typography>
				</div>
			)}

			{/* Restaurant Details */}
			{place?.placeType === "restaurant" && (
				<>
					{averageCheck > 0 || place?.paymentMethod ? (
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/price-tag.png"
								alt="price-tag"
							/>
							<Typography className={classes.descriptionTitle}>
								Цены и оплата
							</Typography>
						</div>
					) : null}
					{averageCheck > 0 && (
						<div className={classes.ratingBox}>
							<Typography className={classes.descriptionTextBefore}>
								Средний чек на человека:
							</Typography>
							<Typography>{Math.round(averageCheck)} ₽</Typography>
						</div>
					)}
					{/* Payment method */}
					{place?.paymentMethod && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Способ оплаты:{" "}
							</Typography>
							<Typography>{place.paymentMethod}</Typography>
						</div>
					)}
					{/* Cuisine */}
					{place?.cuisine?.length > 0 && (
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/plus.png"
								alt="plus"
							/>
							<Typography className={classes.descriptionTitle}>
								Особенности
							</Typography>
						</div>
					)}
					{place?.cuisine?.length > 0 && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Кухня:{" "}
							</Typography>
							<Typography>{place.cuisine.join(", ")}</Typography>
						</div>
					)}
				</>
			)}

			{/* Museum Ticket Prices */}
			{place?.placeType === "museum" && averageTicketAdult > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена взрослого билета: {Math.round(averageTicketAdult)} ₽
					</Typography>
				</div>
			)}

			{place?.placeType === "museum" && averageTicketChild > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена льготного билета: {Math.round(averageTicketChild)} ₽
					</Typography>
				</div>
			)}
		</div>
	);
};

export default PlaceInformation;
