import {
	CREATE_PLACE_SELECTION,
	FETCH_PLACE_SELECTIONS,
	GET_PLACE_SELECTION_BY_ID,
	ADD_CITY_TO_PLACE_SELECTION,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const createPlaceSelection = (newSelection) => async (dispatch) => {
	try {
		const { data } = await api.createPlaceSelection(newSelection);
		dispatch({ type: CREATE_PLACE_SELECTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const fetchPlaceSelections =
	(page, limit, cityId) => async (dispatch) => {
		try {
			const { data } = await api.fetchPlaceSelections(page, limit, cityId);
			dispatch({ type: FETCH_PLACE_SELECTIONS, payload: data });
		} catch (error) {
			console.log(error.message);
		}
	};

export const getPlaceSelectionById = (id) => async (dispatch) => {
	try {
		const { data } = await api.getPlaceSelectionById(id);
		dispatch({ type: GET_PLACE_SELECTION_BY_ID, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const updatePlaceSelection = (id, selection) => async (dispatch) => {
	try {
		const { data } = await api.updatePlaceSelection(id, selection);
		dispatch({ type: CREATE_PLACE_SELECTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const addCityToPlaceSelection =
	(placeSelectionId, cityId) => async (dispatch) => {
		try {
			const { data } = await api.addCityToPlaceSelection(
				placeSelectionId,
				cityId
			);
			dispatch({ type: ADD_CITY_TO_PLACE_SELECTION, payload: data });
		} catch (error) {
			console.log(error.message);
		}
	};
