// src/utils/placeUtils.js

export const translatePlaceType = (placeType) => {
	switch (placeType) {
		case "city":
			return "Город";
		case "restaurant":
			return "Ресторан";
		case "hotel":
			return "Отель";
		case "site":
			return "Достопримечательность";
		case "park":
			return "Парк";
		case "theatre":
			return "Театр";
		case "transport":
			return "Прочий транспорт";
		case "culture":
			return "Культура";
		case "museum":
			return "Музей";
		case "church":
			return "Церковь";
		case "airport":
			return "Аэропорт";
		case "railway":
			return "Вокзал";
		case "other":
			return "Другое";
		default:
			return "";
	}
};

export const switchIcons = (placeType) => {
	switch (placeType) {
		case "city":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png";
		case "restaurant":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/restaurant.png";
		case "hotel":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/bed.png";
		case "site":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/chichen-itza.png";
		case "park":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/forest.png";
		case "theatre":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/theatre-mask.png";
		case "transport":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/ground-transportation.png";
		case "culture":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/theatre-mask.png";
		case "museum":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/museum.png";
		case "church":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/church.png";
		case "airport":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/airport.png";
		case "railway":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/train.png";
		case "other":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/more.png";
		default:
			return "";
	}
};

export const currencyChange = (placeType) => {
	switch (placeType) {
		case "RUB":
			return "₽";
		case "USD":
			return "$";
		case "EUR":
			return "€";
		case "CNY":
			return "¥";
		case "Other":
			return "у.е.";
		default:
			return "";
	}
};

export const translateRoomType = (placeType) => {
	switch (placeType) {
		case "single":
			return "Одноместный";
		case "double":
			return "Двухместный";
		case "family":
			return "Семейный";
		case "suit":
			return "Люкс";
		case "Other":
			return "Другой";
		default:
			return "";
	}
};

export const translatePlaceTypePlural = (placeType) => {
	switch (placeType) {
		case "city":
			return "Города";
		case "restaurant":
			return "Рестораны";
		case "hotel":
			return "Отели";
		case "site":
			return "Достопримечательности";
		case "park":
			return "Парки";
		case "theatre":
			return "Театры";
		case "transport":
			return "Прочий транспорт";
		case "culture":
			return "Культура";
		case "museum":
			return "Музеи";
		case "church":
			return "Церкви";
		case "airport":
			return "Аэропорты";
		case "railway":
			return "Вокзалы";
		case "other":
			return "Другое";
		default:
			return "";
	}
};

export const translatePlaceTypePrepositionalCase = (placeType) => {
	switch (placeType) {
		case "city":
			return "Городе";
		case "restaurant":
			return "Ресторане";
		case "hotel":
			return "Отеле";
		case "site":
			return "Достопримечательности";
		case "park":
			return "Парке";
		case "theatre":
			return "Театре";
		case "transport":
			return "Прочем транспорте";
		case "culture":
			return "Культуре";
		case "museum":
			return "Музее";
		case "airport":
			return "Аэропорте";
		case "railway":
			return "Вокзале";
		case "other":
			return "Другом";
		default:
			return "";
	}
};

export const translateDayToRussian = (day) => {
	const daysTranslation = {
		monday: "Понедельник",
		tuesday: "Вторник",
		wednesday: "Среда",
		thursday: "Четверг",
		friday: "Пятница",
		saturday: "Суббота",
		sunday: "Воскресенье",
	};

	return daysTranslation[day.toLowerCase()] || day;
};

export const translateMonthsToRussian = (month) => {
	if (typeof month !== "string") return month; // Return the input if it's not a string

	const monthsTranslation = {
		jan: "янв",
		feb: "фев",
		mar: "мар",
		apr: "апр",
		may: "май",
		jun: "июн",
		jul: "июл",
		aug: "авг",
		sep: "сен",
		oct: "окт",
		nov: "ноя",
		dec: "дек",
	};

	return monthsTranslation[month.toLowerCase()] || month;
};

export const translateAmenities = (key) => {
	const translations = {
		// General Amenities
		general: "Общие удобства",
		airConditioner: "Кондиционер",
		smokingAreas: "Места для курения",
		heating: "Отопление",
		elevator: "Лифт",
		garden: "Сад",

		// Rooms Amenities
		rooms: "Номера",
		phone: "Телефон",
		TV: "Телевизор",
		smokingRooms: "Номера для курящих",
		nonSmokingRooms: "Номера для некурящих",
		slippers: "Тапочки",
		fridge: "Холодильник",
		minibar: "Мини-бар",
		freeMinibar: "Бесплатный мини-бар",
		freeWater: "Бесплатная вода",
		wardrobe: "Шкаф",
		bathrobe: "Халат",
		teapot: "Чайник",
		coffeeTea: "Чай и кофе бесплатно",
		coffeeMachine: "Кофемашина",
		safe: "Сейф",
		roomService: "Доставка еды в номер",

		// Bathroom Amenities
		bathroom: "Ванная комната",
		ownBathroom: "Собственная ванная",
		bathroomOnFloor: "Ванная на этаже",
		ownBathroomAmenities: "Туалетные принадлежности в индивидуальной упаковке",
		dosatorAmenities: "Туалетные принадлежности в дозаторе",
		showerInAllRooms: "Душ во всех номерах",
		showerInPartRooms: "Душ в некоторых номерах",
		bathInAllRooms: "Ванная во всех номерах",
		bathInPartRooms: "Ванная в некоторых номерах",

		// Bedroom Amenities
		bedroom: "Спальня",
		pillowChoice: "Меню подушек",

		// Services Amenities
		services: "Услуги",
		laundry: "Прачечная",
		selfLaundry: "Прачечная самообслуживания",
		baggageRoom: "Комната для багажа",
		ATM: "Банкомат",
		currencyExchange: "Обмен валюты",

		// Food Amenities
		food: "Питание",
		bar: "Бар",
		breakfastBuffet: "Шведский стол на завтрак",
		breakfastContinental: "Континентальный завтрак",

		// Internet Amenities
		internet: "Интернет",
		freeWifi: "Бесплатный Wi-Fi",
		paidWifi: "Платный Wi-Fi",

		// Parking Amenities
		parking: "Паркинг",
		freeParking: "Бесплатная парковка",
		paidParking: "Платная парковка",

		// Business Amenities
		business: "Бизнес и командировки",
		conferenceHall: "Конференц-зал",
		printing: "Печать",

		// Accessibility Amenities
		accessibility: "Доступность",
		accesibleRooms:
			"Специализированные номера для лиц с ограниченными возможностями",

		// Wellness Amenities
		wellness: "Активности и спорт",
		indoorPool: "Крытый бассейн",
		outdoorPool: "Открытый бассейн",
		heatedPool: "Подогреваемый бассейн",
		sauna: "Сауна",
		fitness: "Фитнес",
		spaCenter: "СПА-центр",
		ownBeach: "Собственный пляж",
		nearBeach: "Пляж рядом",
		beachFirstLine: "Первая линия (пляж)",
		beachSecondLine: "Вторая линия (пляж)",
		aquapark: "Аквапарк",
		bowling: "Боулинг",
		billiards: "Бильярд",
	};
	return translations[key] || key;
};

export const switchAmenitiesImage = (amenitiesType) => {
	switch (amenitiesType) {
		case "general":
			return "https://img.icons8.com/sf-regular/48/ec6262/info.png";
		case "rooms":
			return "https://img.icons8.com/sf-regular/48/ec6262/door-hanger.png";
		case "bathroom":
			return "https://img.icons8.com/ios/50/ec6262/shower.png";
		case "bedroom":
			return "https://img.icons8.com/sf-regular/48/ec6262/bed.png";
		case "services":
			return "https://img.icons8.com/sf-regular/48/ec6262/service-bell.png";
		case "food":
			return "https://img.icons8.com/sf-regular/48/ec6262/pizza.png";
		case "internet":
			return "https://img.icons8.com/sf-regular/48/ec6262/internet.png";
		case "parking":
			return "https://img.icons8.com/sf-regular/48/ec6262/garage.png";
		case "business":
			return "https://img.icons8.com/sf-regular/48/ec6262/briefcase.png";
		case "accessibility":
			return "https://img.icons8.com/sf-regular/48/ec6262/wheelchair.png";
		case "wellness":
			return "https://img.icons8.com/sf-regular/48/ec6262/outdoor-swimming-pool.png";
		default:
			return "";
	}
};
