import {
	ADD_ALERT,
	DELETE_ALL_ALERTS,
	ADD_LAST_PHOTOS,
	ADD_LAST_COMMENTS,
	ADD_LAST_PLACES,
} from "../constants/actionTypes";

const adminReducer = (
	state = { alert: null, lastPhotos: [], lastComments: [], lastPlaces: [] },
	action
) => {
	switch (action.type) {
		case ADD_ALERT:
			return { ...state, alert: action.payload };
		case DELETE_ALL_ALERTS:
			return { ...state, alert: null };
		case ADD_LAST_PHOTOS:
			return { ...state, lastPhotos: action.payload };
		case ADD_LAST_COMMENTS:
			return { ...state, lastComments: action.payload };
		case ADD_LAST_PLACES:
			return { ...state, lastPlaces: action.payload };
		default:
			return state;
	}
};

export default adminReducer;
