import {
	EDIT_PROFILE,
	FETCH_USER,
	FETCH_USER_IMG,
	START_LOADING_USER,
	END_LOADING_USER,
	EDIT_COUNTRIES,
	START_LOADING_AVATAR,
	ADD_YANDEX_AVATAR,
	END_LOADING_AVATAR,
	GET_NUMBER_USERS,
	FETCH_USERS,
	FETCH_USER_SUBSCRIBERS,
	FETCH_USER_FRIENDS,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const updateProfile = (formData, login) => async (dispatch) => {
	try {
		const { data } = await api.updateProfile(formData);
		localStorage.setItem("profile", JSON.stringify(data));
		dispatch({ type: EDIT_PROFILE, payload: data });
		return data; // Return data on success
	} catch (error) {
		// Check if there's data in the error response
		if (error.response && error.response.data && error.response.data.error) {
			// If there's an error message, log it and return it
			return error.response.data;
		} else {
			// If there's no specific error message, log the error and return it
			return error;
		}
	}
};

export const updateCountries = (country, login) => async (dispatch) => {
	try {
		const { data } = await api.updateCountries(country, login);
		let oldUser = JSON.parse(localStorage.getItem("profile"));
		oldUser.result.countriesList = data;
		localStorage.setItem("profile", JSON.stringify(oldUser));
		dispatch({ type: EDIT_COUNTRIES, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getUserInfo = (userId) => async (dispatch) => {
	try {
		const { data } = await api.getUserInfo(userId);
		dispatch({ type: FETCH_USER, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getUserInfoByLogin = (login) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_USER });
		const { data } = await api.getUserInfoByLogin(login);
		dispatch({ type: FETCH_USER, payload: data });
		dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error);
	}
};

export const getPostUserInfo = (postId) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_USER });
		const { data } = await api.getPostUserInfo(postId);
		dispatch({ type: FETCH_USER, payload: data });
		dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error);
	}
};
export const getPostUserImg = (postId) => async (dispatch) => {
	try {
		const { data } = await api.getPostUserImg(postId);
		dispatch({ type: FETCH_USER_IMG, payload: data });
		return data;
	} catch (error) {
		console.log(error);
	}
};

export const getReportUserInfo = (reportId) => async (dispatch) => {
	try {
		const { data } = await api.getReportUserInfo(reportId);
		dispatch({ type: FETCH_USER, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const addYandexAvatar = (image) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_AVATAR });
		const { data } = await api.addYandexAvatar(image);
		dispatch({ type: ADD_YANDEX_AVATAR, payload: data });
		dispatch({ type: END_LOADING_AVATAR });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const getNumberUsers = () => async (dispatch) => {
	try {
		const { data } = await api.getNumberUsers();
		dispatch({ type: GET_NUMBER_USERS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getUsers = (page, searchText) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_USER });
		const { data } = await api.getUsers(page, searchText);
		dispatch({ type: FETCH_USERS, payload: data });
		dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error.message);
	}
};

export const addFriend = (senderId, receiverId) => async (dispatch) => {
	try {
		const { data } = await api.addFriend(senderId, receiverId);
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const unsubscribe = (senderId, receiverId) => async (dispatch) => {
	try {
		const { data } = await api.unsubscribe(senderId, receiverId);
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const getSubscribersByLogin = (login) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_USER });
		const { data } = await api.getSubscribersByLogin(login);
		dispatch({ type: FETCH_USER_SUBSCRIBERS, payload: data });
		//dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error);
	}
};

export const getFriendsByLogin = (login) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_USER });
		const { data } = await api.getFriendsByLogin(login);
		dispatch({ type: FETCH_USER_FRIENDS, payload: data });
		//dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error);
	}
};

export const removeFriend = (senderId, receiverId) => async (dispatch) => {
	try {
		const { data } = await api.removeFriend(senderId, receiverId);
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const getFriends = (page, searchText, login) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_USER });
		const { data } = await api.getFriends(page, searchText, login);
		dispatch({ type: FETCH_USERS, payload: data });
		dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error.message);
	}
};

export const getNumberFriends = (login) => async (dispatch) => {
	try {
		const { data } = await api.getNumberFriends(login);
		dispatch({ type: GET_NUMBER_USERS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getSubscribers = (page, searchText, login) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_USER });
		const { data } = await api.getSubscribers(page, searchText, login);
		dispatch({ type: FETCH_USERS, payload: data });
		dispatch({ type: END_LOADING_USER });
	} catch (error) {
		console.log(error.message);
	}
};

export const getNumberSubscribers = (login) => async (dispatch) => {
	try {
		const { data } = await api.getNumberSubscribers(login);
		dispatch({ type: GET_NUMBER_USERS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
