import React from "react";
import { Bar } from "react-chartjs-2";
import useStyles from "./styles.js";
import { Typography } from "@material-ui/core";
import { translateMonthsToRussian } from "../../../../../utils/placeUtils.js";

const PrecipitationChart = ({ averagePrecipitation }) => {
	const classes = useStyles();

	const translatePrecipitation = (precipitation) => {
		const translatedPrecipitation = {};

		for (const month in precipitation) {
			const translatedMonth = translateMonthsToRussian(month);
			translatedPrecipitation[translatedMonth] = precipitation[month];
		}

		return translatedPrecipitation;
	};

	const translatedAveragePrecipitation =
		translatePrecipitation(averagePrecipitation);
	const labels = Object.keys(translatedAveragePrecipitation);
	const precipitationData = labels.map(
		(month) => translatedAveragePrecipitation[month]
	);

	const data = {
		labels: labels,
		datasets: [
			{
				label: "Среднее количество осадков (мм)",
				data: precipitationData,
				backgroundColor: "rgba(75, 192, 192, 0.5)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	return (
		<div>
			<div className={classes.flex}>
				<img
					width="24"
					height="24"
					src="https://img.icons8.com/sf-regular/ec6262/96/rain.png"
					alt="rain"
				/>
				<Typography className={classes.descriptionTitle}>Осадки</Typography>
			</div>
			<Bar data={data} options={options} height={80} />
		</div>
	);
};

export default PrecipitationChart;
