import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPostSmall } from "../../../../actions/posts.js";
import {
	Typography,
	Paper,
	Button,
	CardMedia,
	Box,
	CardContent,
	Card,
	ButtonBase,
	CardActions,
	Grid,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import useStyles from "./styles.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import { likePostReport } from "../../../../actions/posts.js";
import PlaceReviewWrite from "./PlaceReviewWrite/PlaceReviewWrite.jsx";
import { getPlaceReviews } from "../../../../actions/placeReview.js";
import PlaceReviewCard from "./PlaceReviewCard/PlaceReviewCard.jsx";

const PlaceReviewList = ({ place, user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getPlaceReviews(1, place?._id));
	}, [place]);

	const [isWriteReview, setIsWriteReview] = useState(false);
	const switchWriteReview = () => {
		setIsWriteReview((prevIs) => !prevIs);
	};
	const placeReviews = useSelector((state) => state.placeReview?.placeReviews);

	return (
		<div className={classes.reviewsContainer}>
			<Typography variant="h6" gutterBottom className={classes.reviewsTitle}>
				Отзывы о месте
			</Typography>
			{place?.reviewsId.length === 0 ? (
				<Typography>Отзывов пока нет</Typography>
			) : (
				<Grid container spacing={2}>
					{placeReviews && placeReviews.length > 0 ? (
						placeReviews.map((review, index) => (
							<Grid key={index} item xs={12} sm={6} md={4} lg={4}>
								<PlaceReviewCard place={place} review={review} />
							</Grid>
						))
					) : (
						<Typography>Ошибка</Typography>
					)}
				</Grid>
			)}
			{isWriteReview ? (
				<PlaceReviewWrite
					place={place}
					user={user}
					setIsWriteReview={setIsWriteReview}
				/>
			) : null}
			{user && !isWriteReview ? (
				<Button className={classes.buttonPrimary} onClick={switchWriteReview}>
					Написать отзыв
				</Button>
			) : null}
			{user && isWriteReview ? (
				<Button className={classes.buttonSecondary} onClick={switchWriteReview}>
					Закрыть
				</Button>
			) : null}
		</div>
	);
};

export default PlaceReviewList;
