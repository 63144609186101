import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { YMaps, Map, Placemark, Clusterer } from "@pbe/react-yandex-maps";
import { getPlaces, getPlacesByBorders } from "../../../actions/places.js";
import { PortalGeneral } from "../PortalGeneral/PortalGeneral";
import PlaceBox from "../ShowGeo/PlaceBox/PlaceBox.jsx";
import marker from "../../../images/marker.png";
import { Typography, Button } from "@material-ui/core";
import SearchGeo from "../SelectGeo/SearchGeo/SearchGeo.jsx";
import SelectGeoMap from "../SelectGeo/SelectGeoMap/SelectGeoMap.jsx";
import PlacemarkMemo from "./PlacemarkMemo"; // Import PlacemarkMemo component
import { debounce } from "lodash";
import useStyles from "./styles.js";
import FilterComponent from "./FilterComponent.jsx";
import FilterButtons from "./FilterButtons.jsx";

const MapPlaces = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = document.cookie
		.split("; ")
		.find((row) => row.startsWith("token="));
	const user = token ? JSON.parse(localStorage.getItem("profile")) : undefined;
	const placesLoading = useSelector((state) => state.place.isLoading);
	let places = useSelector((state) => state.place.places);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const filterOptions = [
		{ label: "Город", value: "city" },
		{ label: "Ресторан", value: "restaurant" },
		{ label: "Отель", value: "hotel" },
		// Add more filter options as needed
	];
	const [filteredPlaces, setFilteredPlaces] = useState(places);
	const [isFiltered, setIsFiltered] = useState(false);

	const handleFilterChange = (newFilters) => {
		setSelectedFilters(newFilters);
		console.log(selectedFilters);
		// Handle filter change logic here, such as filtering the data based on selected filters
	};

	console.log(selectedFilters);

	{
		/*const images = [...Array(10)].map((n, i) => {
		return `https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png`;
	});*/
	}
	const limit = 50;
	const [place, setPlace] = useState("");

	useEffect(() => {
		dispatch(getPlaces(limit));
	}, []);

	const mapRef = React.useRef(null);
	const placeBoxRef = React.useRef(null); // Ref for PlaceBox
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const [activePortal, setActivePortal] = useState(false);
	const [numberPlace, setNumberPlace] = useState("");
	const [iconOffset, setIconOffset] = useState([0, 0]); // Initial offset
	const [parentWrapBoxHeight, setParentWrapBoxHeight] = useState(0);
	const [isSearchedPlace, setIsSearchedPlace] = useState(false);
	const [center, setCenter] = useState([55.75, 37.57]);
	const [isAddPlace, setIsAddPlace] = useState(false);

	const [mapBounds, setMapBounds] = useState(null);

	// Define debounced version of handleBoundsChange
	const debouncedHandleBoundsChange = debounce((event) => {
		setMapBounds(event.get("newBounds"));
	}, 500); // Adjust debounce delay as needed

	// Attach debounced handler to map events
	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.events.add("boundschange", debouncedHandleBoundsChange);
		}
		return () => {
			if (mapRef.current) {
				mapRef.current.events.remove(
					"boundschange",
					debouncedHandleBoundsChange
				);
			}
		};
	}, [debouncedHandleBoundsChange]); // Include debounced function in dependencies

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setCenter(center);
		}
	}, [center]);

	useEffect(() => {
		if (mapBounds && mapRef.current) {
			const firstArray = mapBounds[0];
			const secondArray = mapBounds[1];
			const firstBorder = firstArray.join(",");
			const secondBorder = secondArray.join(",");
			dispatch(getPlacesByBorders(limit, firstBorder, secondBorder));
		}
	}, [mapBounds]);

	const ClusterLayout = ymaps?.templateLayoutFactory?.createClass(
		'<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>',
		{}
	);

	const placemarkLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-place" class="driver-card"></div>`,
		{}
	);

	useEffect(() => {
		// Update iconOffset based on PlaceBox height
		if (placeBoxRef.current) {
			const placeBoxHeight = placeBoxRef.current.clientHeight;
			setIconOffset([0, -placeBoxHeight]);
		}
	}, [placeBoxRef.current]);

	const handleWrapBoxHeightChange = (height) => {
		setParentWrapBoxHeight(height);
	};

	const handleSelectedPlace = (selectedPlace) => {
		setPlace(selectedPlace);
		setIsSearchedPlace((prev) => true);
		setCenter((prevCenter) => selectedPlace?.geoposition);
		//setActivePortal(true);
	};

	const handleAddPlace = () => {
		setIsAddPlace((prev) => !prev);
	};

	return (
		<>
			<Helmet>
				<title>Карта - ToTravelRu</title>
				<meta
					name="description"
					content="Карта с туристическими местами, где любой желающий может отметить место на карте и написать посты и отчеты о своих путешествиях - ToTravelRu"
				/>
				<meta
					name="keywords"
					content={`карта, карта с туристическими местами, отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu`}
				/>
			</Helmet>
			<div className={classes.mapTitleButton}>
				<Typography variant="h4">Карта</Typography>
				{user ? (
					<Button className={classes.buttonPrimary} onClick={handleAddPlace}>
						{isAddPlace ? "Вернуться" : "Добавить место на карту"}
					</Button>
				) : null}
			</div>
			{isAddPlace ? (
				<>
					<Typography variant="subtitle2">
						Нажмите на карте на место, которое хотите добавить
					</Typography>
					<SelectGeoMap mapInPost={false} addPlaceOnMap={true} />
				</>
			) : (
				<>
					<Typography variant="subtitle2">Найти место на карте</Typography>
					<div className={classes.margin}></div>
					<SearchGeo onSelectedPlace={handleSelectedPlace} />
					<div className={classes.margin}></div>
					{/*
					<FilterButtons
						places={places}
						setFilteredPlaces={setFilteredPlaces}
						filterOptions={filterOptions}
						onChange={handleFilterChange}
						setIsFiltered={setIsFiltered}
					/>
					*/}
					{/*<FilterComponent places={places} />*/}
					<div className={classes.margin}></div>
					<YMaps
						query={{
							load: "package.full",
							apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
						}}
					>
						<Map
							state={{
								center: center,
								zoom: 2,
								controls: ["zoomControl"],
							}}
							width="100%"
							height="83vh"
							onClick={(event) => {
								const coords = event.get("coords");
								const bounds = event.get("bounds");
							}}
							instanceRef={mapRef}
							onLoad={(ymaps) => setYmaps(ymaps)}
						>
							{isSearchedPlace ? (
								<Placemark
									geometry={place?.geoposition}
									options={{
										iconLayout: activePortal
											? placemarkLayout
											: "default#image",
										iconOffset: activePortal ? iconOffset : [-4, 11], // Use dynamic iconOffset
										iconImageSize: [30, 30],
										iconImageHref: marker,
										iconColor: "rgb(236,98,98)",
										interactivityModel: "default#opaque",
										pane: "balloon",
									}}
									properties={{
										hintContent: place?.placeName,
									}}
									onClick={() => {
										setActivePortal(true);
									}}
								/>
							) : (
								<Clusterer
									options={{
										groupByCoordinates: false,
										clusterIcons: [
											{
												href: "https://img.icons8.com/windows/32/EC6262/filled-circle.png",
												size: [40, 40],
												offset: [-20, -20],
											},
										],
										clusterIconContentLayout: ClusterLayout,
									}}
								>
									{placesLoading
										? null
										: places.map((n, i) => (
												<PlacemarkMemo
													key={i}
													geometry={n.geoposition}
													options={{
														iconLayout:
															activePortal && numberPlace === i
																? placemarkLayout
																: "default#image",
														iconOffset:
															activePortal && numberPlace === i
																? iconOffset
																: [-4, 11], // Use dynamic iconOffset
														iconImageSize: [30, 30],
														iconImageHref: marker,
														iconColor: "rgb(236,98,98)",
														interactivityModel: "default#opaque",
														pane: "balloon",
													}}
													properties={{
														hintContent: n?.placeName,
													}}
													onClick={() => {
														setNumberPlace(i);
														setPlace(places ? places[i] : "");
														setActivePortal(true);
													}}
												/>
										  ))}
								</Clusterer>
							)}
						</Map>
					</YMaps>
					{activePortal && (
						<PortalGeneral
							getHTMLElementId={"placemark-place"}
							placemarkLayout={placemarkLayout}
						>
							<div ref={placeBoxRef}>
								<PlaceBox
									place={place}
									onWrapBoxHeightChange={handleWrapBoxHeightChange}
								/>
							</div>
						</PortalGeneral>
					)}
				</>
			)}
		</>
	);
};

export default MapPlaces;
