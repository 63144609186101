import {
	ADD_ALERT,
	DELETE_ALL_ALERTS,
	ADD_LAST_PHOTOS,
	ADD_LAST_COMMENTS,
	ADD_LAST_PLACES,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addAlert = (alertText, type, expireAt, id) => async (dispatch) => {
	try {
		const { data } = await api.addAlert(alertText, type, expireAt, id);
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLatestAlert = () => async (dispatch) => {
	try {
		const { data } = await api.getLatestAlert();
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteAllAlerts = (id) => async (dispatch) => {
	try {
		const { data } = await api.deleteAllAlerts(id);
		dispatch({ type: DELETE_ALL_ALERTS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastPhotos = () => async (dispatch) => {
	try {
		const { data } = await api.getLastPhotos();
		dispatch({ type: ADD_LAST_PHOTOS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastComments = () => async (dispatch) => {
	try {
		const { data } = await api.getLastComments();
		dispatch({ type: ADD_LAST_COMMENTS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastPlaces = () => async (dispatch) => {
	try {
		const { data } = await api.getLastPlaces();
		dispatch({ type: ADD_LAST_PLACES, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const emptyImagesForAllPlaces = () => async () => {
	try {
		const { data } = await api.emptyImagesForAllPlaces();
		//dispatch({ type: ADD_LAST_PLACES, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
