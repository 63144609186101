import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { getLastPhotos } from "../../../actions/admin";
import { Link } from "react-router-dom"; // Import Link

const LastPhotos = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const lastPhotos = useSelector((state) => state?.admin?.lastPhotos); // Accessing lastPhotos from Redux state

	const handleLoadLastPhotos = () => {
		dispatch(getLastPhotos());
	};

	return (
		<div>
			<Typography variant="h5">Последние фото</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={handleLoadLastPhotos}
			>
				Загрузить последние фото
			</Button>
			<Grid container spacing={2} className={classes.gridContainer}>
				{lastPhotos && lastPhotos.length > 0 ? (
					lastPhotos.map((photo) => (
						<Grid item xs={6} sm={4} md={2} key={photo._id}>
							<div className={classes.photoContainer}>
								<Typography variant="subtitle1">{photo?.name}</Typography>
								<Link
									to={`/user/${photo?.login}`}
									className={classes.profileLink}
								>
									<Typography variant="subtitle1">{photo?.login}</Typography>
								</Link>
								<img
									src={photo?.image}
									alt="last photo"
									className={classes.photo}
								/>
							</div>
						</Grid>
					))
				) : (
					<Typography>Фотографии отсутствуют</Typography>
				)}
			</Grid>
		</div>
	);
};

export default LastPhotos;
