import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		paddingLeft: "30px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
}));
