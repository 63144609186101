import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	gridContainer: {
		marginTop: theme.spacing(2),
	},
	photoContainer: {
		textAlign: "center",
	},
	photo: {
		width: "100%",
		height: "auto",
		borderRadius: "8px",
	},
}));
