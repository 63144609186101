import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	buttonPrimary: {
		marginTop: theme.spacing(1),
		marginRight: "5px",
		borderRadius: "20px",
		backgroundColor: "rgba(236, 98, 98, 1)",
		color: "rgba(255,255,255,1)",
		padding: theme.spacing(1, 4),
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	authorBox: {
		margin: "0 5px 5px 5px",
		padding: "5px 5px 5px 10px",
		background: "none repeat scroll 0 0 rgba(0,0,0,0.75)",
		color: "rgb(256,256,256)",
		borderRadius: "15px",
		width: "fit-content",
		position: "absolute",
		bottom: 0,
		right: 0,
	},
	boxType: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: "10px",
		margin: "5px auto",
	},
	boxTypeText: {
		color: "rgb(255,255,255)",
		marginRight: "10px",
		textTransform: "none",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
	buttonCity: {
		padding: "0",
	},
	visitedIcon: {
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
}));

export default useStyles;
