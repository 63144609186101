import React, { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import {
	TextField,
	Button,
	Typography,
	Paper,
	CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updatePost } from "../../../actions/posts.js";
import { addYandexImageReport } from "../../../actions/posts";
import Select from "react-select";
import PublishIcon from "@material-ui/icons/Publish";
import Resizer from "react-image-file-resizer";

const ReportUpdate = (post) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoadingPhoto = useSelector((state) => state.posts.isLoadingPhoto);
	const [updatedPost, setUpdatedPost] = useState(post?.post);
	const isLoading = useSelector((state) => state?.posts?.isLoading);
	const postId = post?.post?._id;
	const options = useMemo(() => countryList().getData(), []);
	const [country, setCountry] = useState(
		updatedPost?.tags > 1
			? null
			: options.filter((country) => country.label === updatedPost?.tags)
	);
	const [fileName, setFileName] = useState("");
	const addCountries = (country) => {
		setCountry(country);
		let countryArr = country.map((c) => (c = c.label));
		setUpdatedPost({ ...updatedPost, tags: countryArr });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await dispatch(updatePost(postId, updatedPost));
		window.location.reload(false);
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	if (isLoading)
		return (
			<Paper className={classes.circular}>
				<CircularProgress color="secondary" size="10rem" />
			</Paper>
		);

	return (
		<Paper className={classes.paperPost}>
			<form
				autoComplete="off"
				noValidate
				className={`${classes.root} ${classes.form}`}
				onSubmit={handleSubmit}
			>
				<Typography
					gutterBottom
					variant="h6"
					className={classes.writePostTitle}
				>
					Измените отчет
				</Typography>
				<TextField
					name="title"
					variant="outlined"
					label="Заголовок"
					fullWidth
					value={updatedPost?.title}
					className={classes.formType}
					inputProps={{
						maxLength: 100,
					}}
					onChange={(e) =>
						setUpdatedPost({ ...updatedPost, title: e.target.value })
					}
				/>
				<TextField
					name="message"
					variant="outlined"
					label="Текст"
					fullWidth
					multiline
					maxRows={5}
					value={updatedPost?.message}
					className={classes.formType}
					inputProps={{
						maxLength: 1000,
					}}
					onChange={(e) =>
						setUpdatedPost({ ...updatedPost, message: e.target.value })
					}
				/>
				<Select
					options={options}
					value={country}
					onChange={addCountries}
					styles={{
						container: (styles) => ({
							...styles,
							width: "100%",
							minHeight: "56px",
							marginTop: "8px",
						}),
						control: (styles) => ({
							...styles,
							fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							minHeight: "56px",
						}),
						option: (styles) => {
							return {
								...styles,
								fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							};
						},
					}}
					isMulti
					placeholder="Выберите страну"
				/>
				<div>
					<div className={classes.fileInput}>
						<div className={classes.buttonImgBox}>
							<label className={classes.labelInput}>
								<input
									type="file"
									className={classes.imageInput}
									onChange={async (event) => {
										try {
											const file = event.target.files[0];
											const fileNameInput = event.target.files[0].name;
											const image = await resizeFile(file);
											let yandexImage = await dispatch(
												addYandexImageReport(image)
											);

											setUpdatedPost({
												...updatedPost,
												selectedFile: yandexImage,
											});
											setFileName(fileNameInput);
										} catch (err) {
											console.log(err);
										}
									}}
								/>
								{isLoadingPhoto ? null : <PublishIcon />}
								{isLoadingPhoto ? (
									<CircularProgress
										style={{ color: "white", margin: "0 auto" }}
										size="1rem"
									/>
								) : (
									<Typography className={classes.uploadText}>
										ЗАГРУЗИТЬ ФОТО
									</Typography>
								)}
							</label>
							<img
								className={classes.media}
								src={
									updatedPost?.selectedFile ||
									"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
								}
								alt={updatedPost?.title}
							/>
						</div>
						{fileName ? (
							<div className={classes.fileNameBox}>
								<Typography className={classes.fileText}>
									{fileName ? fileName : null}
								</Typography>
							</div>
						) : null}
					</div>
					<Button
						className={classes.buttonPrimary}
						variant="contained"
						disabled={isLoadingPhoto}
						type="submit"
					>
						<Typography>Сохранить изменения</Typography>
					</Button>
				</div>
			</form>
		</Paper>
	);
};

export default ReportUpdate;
