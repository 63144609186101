import { Typography } from "@material-ui/core";
import React from "react";
import { Line } from "react-chartjs-2";
import useStyles from "./styles.js";
import { translateMonthsToRussian } from "../../../../../utils/placeUtils.js";
import PrecipitationChart from "../PrecipitationChart/PrecipitationChart.jsx";

const TemperatureChart = ({ averageTempDay, averageTempNight }) => {
	const classes = useStyles();

	const translateAverageTemp = (averageTemp) => {
		const translatedTemp = {};

		for (const month in averageTemp) {
			const translatedMonth = translateMonthsToRussian(month); // Assuming translateMonths is defined
			translatedTemp[translatedMonth] = averageTemp[month];
		}

		return translatedTemp;
	};

	const translatedAverageTempDay = translateAverageTemp(averageTempDay);
	const translatedAverageTempNight = translateAverageTemp(averageTempNight);
	const labels = Object.keys(translatedAverageTempDay); // ["янв", "фев", ..., "дек"]
	const dayTemperatures = labels.map(
		(month) => translatedAverageTempDay[month]
	);
	const nightTemperatures = labels.map(
		(month) => translatedAverageTempNight[month]
	);

	const data = {
		labels: labels,
		datasets: [
			{
				label: "Средняя температура днем (°C)",
				data: dayTemperatures,
				borderColor: "rgba(255, 99, 132, 1)",
				backgroundColor: "rgba(255, 99, 132, 0.2)",
				fill: true,
			},
			{
				label: "Средняя температура ночью (°C)",
				data: nightTemperatures,
				borderColor: "rgba(54, 162, 235, 1)",
				backgroundColor: "rgba(54, 162, 235, 0.2)",
				fill: true,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	return (
		<div>
			<div className={classes.flex}>
				<img
					width="24"
					height="24"
					src="https://img.icons8.com/sf-regular/ec6262/96/temperature.png"
					alt="temperature"
				/>
				<Typography className={classes.descriptionTitle}>
					Температура
				</Typography>
			</div>
			<Line data={data} options={options} />
		</div>
	);
};

export default TemperatureChart;
