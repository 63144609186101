import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"; // Import useHistory
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
	Box,
	Grid,
	Typography,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	IconButton,
	Link as MuiLink,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"; // Import CloseIcon
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles"; // Importing styles
import { getPhotoByUrl } from "../../actions/photo";
import moment from "moment";
import { Link } from "react-router-dom";

const AboutPhoto = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { folder, name } = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const photoObject = useSelector((state) => state?.photo?.selectedPhoto);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile devices
	const photoURL = `https://totravelru.storage.yandexcloud.net/${folder}/${name}`; // Image link

	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls the window to the top of the page when the component mounts

		const fetchPhotoDescription = async () => {
			try {
				setLoading(true);
				setError(null);
				const response = await dispatch(getPhotoByUrl(photoURL));
			} catch (error) {
				setError("Failed to load photo description");
			} finally {
				setLoading(false);
			}
		};

		fetchPhotoDescription();
	}, [dispatch, folder, name, photoURL]);

	// Handle close button click, return to previous page
	const handleClose = () => {
		history.goBack(); // Go back to the previous page
	};

	if (loading) {
		return (
			<Grid container spacing={2} className={classes.loader}>
				<CircularProgress color="inherit" />
			</Grid>
		);
	}

	if (error) {
		return (
			<Typography variant="h6" color="error">
				{error}
			</Typography>
		);
	}

	return (
		<Grid container spacing={2} className={classes.root}>
			{/* Photo Section */}
			<Grid
				item
				xs={12}
				md={isMobile ? 12 : 8}
				className={classes.photoContainer}
			>
				{/* Close Button */}
				<IconButton
					className={classes.closeButton}
					onClick={handleClose}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
				<CardMedia
					component="img"
					alt="Photo"
					image={photoURL}
					title={name}
					className={classes.media}
				/>
			</Grid>

			{/* Details Section */}
			<Grid item xs={12} md={isMobile ? 12 : 4}>
				<Card className={classes.detailsCard}>
					<CardContent className={classes.detailsCardContent}>
						{/* User info */}
						<Link
							to={`/user/${photoObject?.login}`}
							className={classes.brandContainer}
						>
							<Box className={classes.authorInfo}>
								<img
									src={
										photoObject?.userPicture
											? photoObject?.userPicture
											: "https://via.placeholder.com/50"
									}
									alt="Аватар"
									className={classes.avatar}
								/>
								<Box ml={2}>
									<Typography variant="h6">
										{photoObject?.name || "Имя пользователя"}
									</Typography>
									<Typography variant="body2">
										{photoObject?.login ? `@${photoObject?.login}` : "Логин"}
									</Typography>
								</Box>
							</Box>
						</Link>

						{/* Photo Details */}
						<Box mt={2}>
							<Typography variant="body1">
								<strong>Автор:</strong>{" "}
								{photoObject?.isAuthorCreator ? (
									photoObject?.name
								) : photoObject?.authorLink ? (
									<MuiLink
										href={photoObject?.authorLink}
										target="_blank"
										rel="noopener noreferrer"
										style={{ color: "white", textDecoration: "underline" }}
									>
										{photoObject?.author}
									</MuiLink>
								) : (
									photoObject?.author
								)}
								{photoObject?.websiteName && ` // ${photoObject?.websiteName}`}
							</Typography>
							<Typography variant="body1">
								<strong>Лицензия:</strong>{" "}
								{photoObject?.licenseType || "Не указана"}
							</Typography>
							{photoObject?.photoShootAt && (
								<Typography variant="body1">
									<strong>Дата съемки:</strong>{" "}
									{photoObject?.photoShootAt
										? moment(photoObject?.photoShootAt)
												.locale("ru")
												.format("DD MMMM YYYY")
										: "Не указано"}
								</Typography>
							)}
						</Box>

						{/* Comments Section (Leave empty for now) */}
						{photoObject?.comments.length > 0 && (
							<Box mt={2}>
								<Typography variant="h6">Комментарии</Typography>
								{/* Placeholder for comments */}
							</Box>
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default AboutPhoto;
