import {
	AUTH_LOGIN,
	AUTH_SIGNUP,
	VERIFY_TOKEN,
	RESEND_EMAIL,
	ERROR,
	SEND_CHANGE_PASSWORD,
	CHANGE_PASSWORD,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const signin = (formData, router) => async (dispatch) => {
	try {
		const data = await api.signIn(formData);

		dispatch({ type: AUTH_LOGIN, payload: data });

		router.push("/main");
	} catch (error) {
		console.log(error);
		dispatch({ type: ERROR, payload: error });
	}
};

export const signup = (formData, router) => async (dispatch) => {
	try {
		const { data } = await api.signUp(formData);

		dispatch({ type: AUTH_SIGNUP, payload: data });

		router.push("/verify");
	} catch (error) {
		console.error(error);
		dispatch({ type: ERROR, payload: error });
	}
};

export const confirmEmail = (token) => async (dispatch) => {
	try {
		const data = await api.confirmEmail(token);

		dispatch({ type: VERIFY_TOKEN, payload: data });
	} catch (error) {
		console.error(error);
	}
};

export const resendConfirmEmail = (email) => async (dispatch) => {
	try {
		const data = await api.resendConfirmEmail(email);

		dispatch({ type: RESEND_EMAIL, payload: data });
	} catch (error) {
		console.error(error);
	}
};

export const sendChangePassword = (email) => async (dispatch) => {
	try {
		const data = await api.sendChangePassword(email);

		dispatch({ type: SEND_CHANGE_PASSWORD, payload: data });
	} catch (error) {
		console.error(error);
	}
};

export const changePassword = (token, formData) => async (dispatch) => {
	try {
		const data = await api.changePassword(token, formData);

		dispatch({ type: CHANGE_PASSWORD, payload: data });
	} catch (error) {
		console.error(error);
	}
};
