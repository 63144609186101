import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	imageWrapper: {
		width: "100%",
		maxWidth: "500px",
		textAlign: "center",
		position: "relative",
		display: "inline-block",
	},
	authorText: { fontSize: "0.65rem" },
	authorTextLarge: { fontSize: "0.85rem" },
	changesToPhotoText: {
		fontSize: "0.57rem",
		fontWeight: "100",
		fontStyle: "italic",
	},
}));
